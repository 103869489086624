import { React, useState, useEffect, useRef } from "react";
import DatalistInput from "react-datalist-input";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { Helmet } from "react-helmet";
import axios from "axios";

import Header from "../../components/Header/Header";
import Product from "../../components/Product/Product";
import LabelRadioBtn from "../../components/LabelRadioBtn/LabelRadioBtn";
import InputFile from "../../components/InputFile/InputFile";
// import useStickyState from "../../assets/js/useStickyState";
// import StageSmall from '../../components/StageSmall/StageSmall';
import styles from "./FirstCount.scss";
import clear from "../../assets/img/clear.svg";
import closeGreen from "../../assets/img/close-green.svg";
import close from "../../assets/img/close.svg";
import "react-datalist-input/dist/styles.css";
import spinner from "../../assets/img/spinner.svg";
import FirstCountStep from "../../components/FirstCountStep/FirstCountStep";

const FirstCount = ({ changeTheme, themeBlack }) => {
	const token = JSON.parse(localStorage.getItem("token"));
	const navigate = useNavigate();

	// const stepsElems = [
	// 	{ title: "Дизайн–поддержка (продуктовое направление)", id: 1 },
	// 	{ title: "Диджитал-поддержка (коммуникацинное направление)", id: 2 },
	// 	{ title: "UXUI (только дизайн)", id: 3 },
	// 	{ title: "WEB (под ключ)", id: 4 },
	// 	{
	// 		title: "Креативный носитель (календарь/стенд/выставка/большой многополосный носитель/сувенирка) ",
	// 		id: 5,
	// 	},
	// 	{ title: "Креатив (Big idea / Рекламная кампания)", id: 6 },
	// 	{ title: "Креатив (Big idea / Рекламная кампания)", id: 7 },
	// 	{ title: "Промо-сайт", id: 8 },
	// 	{ title: "Видео", id: 9 },
	// 	{ title: "ВидеоПрочее", id: 10 },
	// 	{ title: "Стратегия", id: 11 },
	// 	{ title: "Графическая поддержка", id: 12 },
	// 	{ title: "Прочее", id: "other" },
	// ];

	// const stepsElems = {
	// 	title: "Этапы",
	// 	required: true,
	// 	type: "radio",
	// 	options: {
	// 		stpWork1: {
	// 			title: "Дизайн–поддержка (продуктовое направление)",
	// 			id: 1,
	// 			it: true,
	// 		},
	// 		stpWork2: {
	// 			title: "Диджитал-поддержка (коммуникацинное направление)",
	// 			id: 2,
	// 			it: true,
	// 		},
	// 		stpWork3: {
	// 			title: "UXUI (только дизайн)",
	// 			id: 3,
	// 			it: true,
	// 		},
	// 		stpWork4: {
	// 			title: "WEB (под ключ)",
	// 			id: 4,
	// 			it: true,
	// 		},
	// 		stpWork5: {
	// 			title: "Креативный носитель (календарь/стенд/выставка/большой многополосный носитель/сувенирка) ",
	// 			id: 5,
	// 		},
	// 		stpWork6: {
	// 			title: "Креатив (Big idea / Рекламная кампания)",
	// 			id: 6,
	// 			it: true,
	// 		},
	// 		stpWork7: {
	// 			title: "Креатив (Big idea / Рекламная кампания)",
	// 			id: 7,
	// 		},
	// 		stpWork8: {
	// 			title: "Промо-сайт",
	// 			id: 8,
	// 			it: true,
	// 		},
	// 		stpWork9: {
	// 			title: "Видео",
	// 			it: true,
	// 			id: 9,
	// 		},
	// 		stpWork10: {
	// 			title: "ВидеоПрочее",
	// 			it: true,
	// 			id: 10,
	// 		},
	// 		stpWork11: {
	// 			title: "Стратегия",
	// 			id: 11,
	// 		},
	// 		stpWork12: {
	// 			title: "Графическая поддержка",
	// 			id: 12,
	// 		},
	// 		stpWork13: {
	// 			title: "Прочее",
	// 			id: "other",
	// 		},
	// 	},
	// };

	const [data, setData] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [taskLink, setTaskLink] = useState("#");

	const [steps, setSteps] = useState([""]);
	const [itemsProduct, setItemsProduct] = useState([""]);
	const [collabProject, setCollabProject] = useState({});
	const [companyLaw, setCompanyLaw] = useState({});
	const [clientCollabName, setClientCollabName] = useState({});
	const [clientCollab, setClientCollab] = useState({});
	const [legalEntity, setLegalEntity] = useState({});
	const [contractOffer, setContractOffer] = useState({});
	const [contractNumber, setContractNumber] = useState({});
	const [clientDoc, setClientDoc] = useState({});
	const [clientApp, setClientApp] = useState({});
	const [contractAppendixNumber, setContractAppendixNumber] = useState({});
	const [projects, setProjects] = useState({});
	const [stages, setStages] = useState({});
	const [stagesTypes, setStagesTypes] = useState({});
	const [stagesOther, setStagesOther] = useState({});

	const [itIsTrue, setItIsTrue] = useState("_it");

	useEffect(() => {
		const fetchData = async () => {
			try {
				const { data } = await axios({
					method: "GET",
					url: `${process.env.REACT_APP_BACKEND_URL}/v1/documents/first-payment/fields`,
					// url: "../ajax/new-response-first-count.json",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: "Bearer " + token,
					},
				});

				if (data) {
					// console.log("data", data);
					setData(data);
					setIsLoaded(true);

					setCompanyLaw(data["company-law"]);
					setClientCollabName(data["collab-client"]);
					setClientCollab(
						Object.entries(data["collab-client"].options).map(
							([key, item]) => ({
								id: item.id,
								value: item.id,
								label: item.title,
							})
						)
					);
					setLegalEntity(data["legal-entity"]);
					setCollabProject(data["collab-project"]);
					setContractOffer(data["contract-offer"]);
					setContractNumber(data["contract-number"]);
					setClientDoc(data["client-doc1"]);
					setClientApp(data["client-doc2"]);
					setContractAppendixNumber(data["contract-appendix-number"]);
					setProjects(data["projects"]);
					setStages(data["stages"]);
					setStagesTypes(data["stages-types"]);
					setStagesOther(data["stages-other"]);
				}
			} catch (error) {
				console.log("fetching error");
			}
		};

		if (token) {
			fetchData();
		} else {
			navigate("/auth");
		}
	}, []);

	const [input, setInput] = useState({
		legalEntity: "Делаем круто",
	});

	// const resultProduct = itemsProduct.map((element, index) => {
	// 	const department = input.department || "rd";
	// 	if (projects && projects.name) {
	// 		return (
	// 			<Product
	// 				id={index + 1}
	// 				key={index}
	// 				hint={projects.hint}
	// 				removeProduct={removeProduct}
	// 				num={index + 1}
	// 				name={(index += 1)}
	// 				projectNum={index++}
	// 				data={
	// 					projects.name.options[department.toLowerCase()].products
	// 				}
	// 			/>
	// 		);
	// 	}
	// 	return false;
	// });

	const resultSteps = steps.map((element, index) => {
		return (
			<FirstCountStep
				id={index + 1}
				key={index}
				removeSteps={removeStep}
				num={index + 1}
				name={(index += 1)}
				input={input}
				stepNum={index++}
				itIsIt={itIsTrue}
				stagesOther={stagesOther}
				showLabelRadioBtnTypes={showLabelRadioBtnStepsTypes}
				showLabelRadioBtnSteps={showLabelRadioBtnSteps}
				data={stages}
				dataTypes={stagesTypes}
			/>
		);
	});

	function addStep(index) {
		setSteps([...steps, index.target.value]);
	}

	function removeStep(index) {
		setSteps([...steps.slice(0, index), ...steps.slice(index + 1)]);
	}

	function addProduct(index) {
		setItemsProduct([...itemsProduct, index.target.value]);
	}

	function removeProduct(index) {
		setItemsProduct([
			...itemsProduct.slice(0, index),
			...itemsProduct.slice(index + 1),
		]);
	}

	function changeCollabProject(choice) {
		if (!!choice) {
			setInput((prev) => ({ ...prev, collabProject: choice.value }));
			// setInput((prev) => ({ ...prev, department: choice.dep }));
		} else {
			setInput((prev) => ({ ...prev, collabProject: null }));
		}
	}

	function changeLabelRadio(nameRadio, valueRadio, idRadio) {
		if (nameRadio.includes("stage-item")) {
			let id = nameRadio.split("_")[1];
			setInput((prev) => ({
				...prev,
				[nameRadio]: valueRadio,
				[nameRadio + "Id" + id]: idRadio,
			}));
		} else {
			setInput((prev) => ({
				...prev,
				[nameRadio]: valueRadio,
				[nameRadio + "Id"]: idRadio,
			}));
		}
	}

	function toggleClassIt(el) {
		let stageNum = el.target.name.split("_")[1];
		let currentStep = ".step-works-" + stageNum;
		let currentStepDOM = document.querySelector(currentStep);

		if (el.target.value === "st1") {
			currentStepDOM.classList.add("_it");
		} else {
			currentStepDOM.classList.remove("_it");
		}
	}

	function toggleClassOther(el) {
		let stepWrap = el.target.closest(".first-count-step");
		let stageNum = el.target.name.split("_")[1];
		let currentStep = ".step-works-" + stageNum;
		let currentStepDOM = document.querySelector(currentStep);
		let stepElems = "stage-item_" + stageNum;
		let stepElemsDOM = document.querySelectorAll(`[name="${stepElems}"]`);

		stepElemsDOM.forEach((item, index) => {
			item.classList.remove("_active");
		});

		el.target.classList.add("_active");
		stepWrap.classList.remove("_no-checked-step");

		if (el.target.value === "s16") {
			currentStepDOM.classList.add("_show-other");
		} else {
			currentStepDOM.classList.remove("_show-other");
		}
	}

	function changeLabelRadioTypes(nameRadio, valueRadio, idRadio) {
		setInput((prev) => ({
			...prev,
			[nameRadio]: valueRadio,
			[nameRadio + "Id"]: idRadio,
		}));

		// toggleClassIt(idRadio);
	}

	function autoChecked(name, value) {
		if (name === "contract-offer") {
			return value === "co1" ? true : false;
		}

		if (name === "legal-entity") {
			return value === "le2" ? true : false;
		}
	}

	function showLabelRadioBtn(obj, nameEl, inputName) {
		return Object.keys(obj.options).map((key) => {
			return (
				<LabelRadioBtn
					defaultChecked={autoChecked(nameEl, obj.options[key].id)}
					key={key}
					changeLabelRadioBtn={() =>
						changeLabelRadio(
							inputName,
							obj.options[key].title,
							obj.options[key].id
						)
					}
					name={nameEl}
					value={obj.options[key].id}
					title={obj.options[key].title}
				/>
			);
		});
	}

	function showLabelRadioBtnStepsTypes(obj, nameEl, inputName) {
		return Object.keys(obj.options).map((key) => {
			return (
				<LabelRadioBtn
					key={key}
					changeLabelRadioBtn={(e) => {
						changeLabelRadioTypes(
							inputName,
							obj.options[key].title,
							obj.options[key].id
						);
						toggleClassIt(e);
					}}
					className="stage-type"
					name={nameEl}
					defaultChecked={
						obj.options[key].id === "st1" ? true : false
					}
					value={obj.options[key].id}
					title={obj.options[key].title}
				/>
			);
		});
	}

	function showLabelRadioBtnSteps(obj, nameEl, inputName) {
		return Object.keys(obj.options).map((key) => {
			return (
				<LabelRadioBtn
					defaultChecked={autoChecked(nameEl, obj.options[key].id)}
					key={key}
					changeLabelRadioBtn={(e) => {
						changeLabelRadio(
							inputName,
							obj.options[key].title,
							obj.options[key].id
						);
						toggleClassOther(e);
					}}
					className="stage-name"
					name={nameEl}
					type={
						obj.options[key].depends_on === "stages-types=st1"
							? true
							: false
					}
					value={obj.options[key].id}
					title={obj.options[key].title}
				/>
			);
		});
	}

	function getInfoArray(dataItem, project, objName) {
		if (!dataItem.options[project]) {
			return [];
		}
		const info = dataItem.options[project][objName];
		const infoArray = Object.entries(info).map(([key, item]) => ({
			id: key,
			value: item.title,
			label: item.title,
		}));

		return infoArray;
	}

	function getInfoArrayCollabProject(dataItem, project, objName) {
		if (!dataItem.options[project]) {
			return [];
		}
		const info = dataItem.options[project][objName];
		const infoArray = Object.entries(info).map(([key, item]) => ({
			id: item.id,
			value: item.id,
			label: item.title,
			// dep: item.department,
		}));

		return infoArray;
	}

	function getContractAppendixNumber(dataItem, project, contractNumber) {
		if (
			!dataItem ||
			!project ||
			!dataItem.options[project].contracts[contractNumber]
		) {
			return [];
		}
		const contractsAppendix =
			dataItem.options[project].contracts[contractNumber].appendix || {};
		const contractsAppendixArray = Object.entries(contractsAppendix).map(
			([key, item]) => ({ id: key, value: item.title, label: item.title })
		);
		return contractsAppendixArray;
	}

	function validateForm(form) {
		const formFields = form.querySelectorAll(`input, textarea, select`);
		const formSteps = form.querySelectorAll(".first-count-step");
		const elementError = document.createElement("div");
		elementError.innerHTML = "Не заполнено поле";
		elementError.classList.add("ajax-error");

		formFields.forEach((item) => {
			let itemName = item.getAttribute("name");
			let itemClass = item.getAttribute("class");

			if (itemClass === "stage-name") {
				elementError.innerHTML = "Не выбран тип работ в этапе";
			}

			// if (itemClass === "input-file") {
			// 	elementError.innerHTML = "Не прикреплён файл договора";
			// }

			if (item.closest('[data-required="true"]')) {
				item.closest(".content-form-item")
					.querySelectorAll(".ajax-error")
					.forEach((e) => e.remove());

				if (
					item.getAttribute("type") === "radio" ||
					item.getAttribute("type") === "checkbox"
				) {
					// if (item.classList.contains("stage-name")) {
					// 	// console.log(itemName);
					// 	// console.log(item.getAttribute("checked"));
					// 	// console.log(
					// 	// 	form.querySelector(`[name="${itemName}"]:checked`)
					// 	// 		.length
					// 	// );
					// 	// if (item.getAttribute("checked") == null) {
					// 	console.log(
					// 		item.getAttribute("class").includes("_active")
					// 	);
					// 	if (!item.getAttribute("class").includes("_active")) {
					// 		item.closest(".content-form-item").prepend(
					// 			elementError
					// 		);
					// 		// console.log("errrr");
					// 	}
					// } else
					if (!form.querySelector(`[name="${itemName}"]:checked`)) {
						item.closest(".content-form-item").prepend(
							elementError
						);
					}
				} else if (item.getAttribute("type") === "file") {
					if (item.value.length === 0) {
						const elementErrorFile = document.createElement("div");
						elementErrorFile.classList.add("ajax-error");
						elementErrorFile.innerHTML =
							"Не прикреплён файл договора";
						item.closest(".content-form-item").prepend(
							elementErrorFile
						);
					}
				} else {
					if (
						(item.value === "" || item.value.length === 0) &&
						!item.closest("._hidden")
					) {
						item.closest(".content-form-item").prepend(
							elementError
						);
					}
				}
			}
		});

		// formSteps.forEach((item, index) => {
		// 	if (item.getAttribute("class").includes("_no-checked-step")) {
		// 		item.closest(".content-form-item").prepend(elementError);
		// 	}
		// 	// let inputsStep = item.querySelectorAll(".stage-name");

		// 	// inputsStep.forEach((item, index) => {
		// 	// 	if (!item.getAttribute("class").includes("_active")) {
		// 	// 		item.closest(".content-form-item").prepend(elementError);
		// 	// 	} else {
		// 	// 	}
		// 	// });

		// 	// if (
		// 	// 	!item.querySelector(`[name="${"stage-item_" + num}"]:checked`)
		// 	// ) {
		// 	// 	console.log("error");
		// 	// 	console.log("elems", `[name="${"stage-item_" + num}"]`);
		// 	// 	console.log("index", index + 1);
		// 	// 	item.prepend(elementError);
		// 	// }
		// });

		const errorMessages = form.querySelectorAll(`.ajax-error`);
		let stepNoChecked = form.querySelectorAll("._no-checked-step");
		if (errorMessages.length > 0 || stepNoChecked.length > 0) {
			// scrollIntoView(form.querySelectorAll(`.ajax-error`)[0]);
			form.querySelectorAll(`.ajax-error`)[0].scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
			// window.scrollTo({
			// 	top: form.querySelectorAll(`.ajax-error`)[0].offsetTop,
			// 	behavior: "smooth",
			// });

			return false;
		}

		return true;
	}

	function handlerFirstCount(e) {
		e.preventDefault();
		const form = e.target;
		form.querySelectorAll(".ajax-error").forEach((e) => e.remove());

		if (!validateForm(form)) {
			return false;
		}

		setIsLoading(true);

		const formData = new FormData(form);
		const formDataObj = {};

		formData.forEach((value, key) => {
			if (key === "files-input") return;
			else if (key.indexOf("other") >= 0) {
				return false;
			}

			if (key.indexOf("uplfiles") >= 0) {
				// собираем данные по файлам
				const fileName = key.replace("uplfiles_", "");
				const fieldName = form.querySelector(`[name="${key}"]`).dataset
					.name;
				formDataObj[fieldName] = {
					"file-name": fileName,
					content: value,
				};
			} else if (key.indexOf("[]") >= 0) {
				key = key.replace("[]", "");
				if (!formDataObj[key]) {
					formDataObj[key] = [value];
				} else {
					formDataObj[key].push(value);
				}
			} else if (key.indexOf("stage-") >= 0) {
				let type = key.split("_")[0];
				let objStage = {};
				let stages = [];
				let stagesElems =
					document.querySelectorAll(".first-count-step");

				stagesElems.forEach((element) => {
					let typeValue = element.querySelector(
						"input.stage-type:checked"
					).value;
					let nameValue = element.querySelector(
						"input.stage-name:checked"
					).value;

					if (nameValue === "s16") {
						let otherValue =
							element.querySelector("input.other-input").value;
						stages.push({
							"stage-type": typeValue,
							id: nameValue,
							other: otherValue,
						});
					} else {
						stages.push({
							"stage-type": typeValue,
							id: nameValue,
						});
					}
				});

				// if (key.indexOf("stage-type") >= 0) {
				// 	objStage["stage-type"] = value;
				// }

				// if (key.indexOf("stage-item") >= 0) {
				// 	objStage["id"] = value;
				// }

				// собираем проекты и этапы
				// if (!Object.keys(formDataObj).includes("stage")) {
				// 	formDataObj.stages = [];
				// }
				// const temp = key.split("_");
				// const formProject = temp[0];
				// const formProjectNumber = formProject.split("_")[1];
				// let formStageItem = temp[1];

				// let objStage = { [formProject]: value, id: formProjectNumber };

				formDataObj.stages = stages;

				// const curProject = formDataObj.stages[formProjectNumber - 1];
				// if (!curProject) {
				// 	formDataObj.stages.push({});
				// }

				// if (formStageItem.indexOf("stage") >= 0) {
				// 	if (!Object.keys(curProject).includes("stages")) {
				// 		curProject.stages = [{}];
				// 	}
				// 	const formStage = temp[1];
				// 	const formStageNumber = formStage.split("-")[1];
				// 	formStageItem = temp[2];

				// 	if (!curProject.stages[formStageNumber - 1]) {
				// 		curProject.stages.push({});
				// 	}
				// 	const curStage = curProject.stages[formStageNumber - 1];
				// 	curStage[formStageItem] = value;
				// 	delete formDataObj[key];
				// } else if (formStageItem === "name") {
				// 	formDataObj.stages[formProjectNumber - 1][formStageItem] =
				// 		value;
				// 	delete formDataObj[key];
				// }
			} else {
				formDataObj[key] = value;
			}
		});

		axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND_URL}/v1/documents/first-payment`,
			// url: "../ajax/create-document-total.json",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"X-Requested-With": "XMLHttpRequest",
				Authorization: "Bearer " + token,
			},
			data: formDataObj,
		})
			.then(function (response) {
				console.log("Success: ", response);
				setIsLoading(false);
				if (response.data.result) {
					setIsSubmitted(true);
					setInput({});
					response.data.task_url &&
						setTaskLink(response.data.task_url);
				}
			})
			.catch(function (response) {
				console.error("Response: ", response);
				console.error("Error: ", response.response.data.error);
				setIsLoading(false);

				const responseError = response.response.data.error;
				const elementError = document.createElement("div");

				if (response.response.status === 422) {
					Object.keys(responseError).map((key) => {
						elementError.innerHTML = responseError[key][0];
					});
				} else {
					elementError.innerHTML = responseError;
				}
				elementError.classList.add("ajax-error");
				form.querySelector(`.submit-block`).prepend(elementError);

				const errorMessages = form.querySelectorAll(`.ajax-error`);
				if (errorMessages.length > 0) {
					window.scrollTo({
						top: form.querySelectorAll(`.ajax-error`)[0].offsetTop,
						behavior: "smooth",
					});
				}
			});
	}

	function handleBackToForm() {
		setIsSubmitted(false);
	}

	if (!isLoaded) {
		return (
			<div>
				<div className="content content-create-document">
					<div className="container">
						<img className="loading" src={spinner} alt="Spinner" />
					</div>
				</div>
			</div>
		);
	}

	// console.log("stages", stages);
	// console.log("stagesTypes", stagesTypes);
	// console.log("INPUT", input);
	// console.log(steps);

	return (
		<div>
			<Helmet>
				<title>Заказ первого счета по новому документу</title>
			</Helmet>
			<div className="content content-first-count">
				<div className="container">
					<h1>Заказ первого счета по новому документу</h1>
					<div className="desc">
						Все поля помеченные <i>*</i> обязательны для заполнения
					</div>
					<div className="first-count-note">
						Перед заполнением формы, ознакомьтесь со статьей в{" "}
						<a
							href="https://wiki.cpeople.ru/x/SACbBg"
							target="_blank">
							Wiki
						</a>
					</div>
					{isSubmitted && (
						<div className="create-document__result">
							<h2>Заявка успешно создана</h2>
							<a
								href={taskLink}
								className="btn-white btn-white-big"
								target="_blank">
								Перейти в задачу
							</a>
							<div
								className="fake-link"
								onClick={handleBackToForm}>
								Создать еще заявку
							</div>
						</div>
					)}
					{!isSubmitted && (
						<form
							className="first-count-form"
							onSubmit={handlerFirstCount}>
							<div className="content-form-item">
								<div className="content-form-item-desc">
									<label htmlFor="">
										{companyLaw.title}{" "}
										{companyLaw.required && <i>*</i>}
									</label>
								</div>

								<div className="content-form-item-elems">
									<input
										onChange={(e) =>
											setInput((prev) => ({
												...prev,
												companyLaw: e.target.value,
											}))
										}
										type="text"
										name="company-law"
										placeholder="Введите название"
									/>
								</div>
							</div>
							<hr />

							<div className="content-form-item">
								<div className="content-form-item-desc">
									<label htmlFor="">
										{clientCollabName.title}{" "}
										{clientCollabName.required && <i>*</i>}
									</label>
								</div>

								<div className="content-form-item-elems">
									<div className="content-select">
										<ReactSelect
											onChange={(choice) =>
												setInput((prev) => ({
													...prev,
													clientCollab: choice.id,
												}))
											}
											name="collab-client"
											placeholder="Введите название"
											options={clientCollab}
											className="react-select-container"
											classNamePrefix="react-select"
										/>
									</div>
								</div>
							</div>
							<hr />

							<div className="_hidden">
								<div className="content-form-item">
									<div className="content-form-item-desc">
										<label htmlFor="">
											{legalEntity.title}{" "}
											{legalEntity.required && <i>*</i>}
										</label>
									</div>

									<div className="content-form-item-elems">
										<div className="label-radio-btns-wrap">
											{showLabelRadioBtn(
												legalEntity,
												"legal-entity",
												"legalEntity"
											)}
										</div>
									</div>
								</div>
								<hr />
							</div>

							<div
								className="content-form-item"
								data-required="true">
								<div className="content-form-item-desc">
									<label htmlFor="">
										{collabProject.title}{" "}
										{collabProject.required && <i>*</i>}
									</label>
									<p
										dangerouslySetInnerHTML={{
											__html: collabProject.hint,
										}}></p>
								</div>

								<div className="content-form-item-elems">
									<div className="content-select">
										<ReactSelect
											onChange={changeCollabProject}
											isClearable={true}
											options={getInfoArrayCollabProject(
												collabProject,
												input.clientCollab,
												"projects"
											)}
											name="collab-project"
											className="react-select-container"
											classNamePrefix="react-select"
											placeholder="Введите название"
										/>
									</div>
								</div>
							</div>
							<hr />

							{input.companyLaw != null &&
								input.companyLaw != "" &&
								input.clientCollab != null &&
								input.clientCollab != "" &&
								input.collabProject != null &&
								input.collabProject != "" && (
									<>
										<div className="content-form-item">
											<div className="content-form-item-desc">
												<label htmlFor="">
													{contractOffer.title}{" "}
													{contractOffer.required && (
														<i>*</i>
													)}
												</label>
											</div>

											<div
												className="content-form-item-elems"
												data-required={
													contractOffer.required
														? true
														: false
												}>
												<div className="label-radio-btns-wrap">
													{showLabelRadioBtn(
														contractOffer,
														"contract-offer",
														"contractOffer"
													)}
												</div>
											</div>
										</div>
										<hr />

										<div
											className={
												input.contractOfferId === "co2"
													? "_hidden"
													: ""
											}>
											<div className="content-form-item">
												<div className="content-form-item-desc">
													<label htmlFor="">
														{contractNumber.title}{" "}
														{contractNumber.required && (
															<i>*</i>
														)}
													</label>
													<p
														dangerouslySetInnerHTML={{
															__html: contractNumber.hint,
														}}></p>
												</div>

												<div
													className="content-form-item-elems"
													data-required={
														contractNumber.required
															? true
															: false
													}>
													<DatalistInput
														className="datalist-input"
														placeholder="Введите номер договора"
														inputProps={{
															name: "contract-number",
														}}
														items={getInfoArray(
															contractNumber,
															input.clientCollab,
															"contracts"
														)}
														onSelect={(item) =>
															setInput(
																(prev) => ({
																	...prev,
																	contractNumber:
																		item.id,
																})
															)
														}
														value=""
													/>

													<div className="adds-files">
														<b>
															{clientDoc.title}{" "}
															{clientDoc.required && (
																<i className="required-icon">
																	*
																</i>
															)}
														</b>
														<span>
															Загрузите 1 файл
														</span>

														<InputFile
															name="client-doc1"
															button={true}
														/>
													</div>
												</div>
											</div>
											<hr />

											<div className="content-form-item">
												<div className="content-form-item-desc">
													<label htmlFor="">
														{
															contractAppendixNumber.title
														}{" "}
														{contractAppendixNumber.required && (
															<i>*</i>
														)}
													</label>
													<p
														dangerouslySetInnerHTML={{
															__html: contractAppendixNumber.hint,
														}}></p>
												</div>

												<div className="content-form-item-elems">
													<DatalistInput
														className="datalist-input"
														items={getContractAppendixNumber(
															contractAppendixNumber,
															input.clientCollab,
															input.contractNumber
														)}
														onSelect={(item) =>
															setInput(
																(prev) => ({
																	...prev,
																	contractAppendixNumber:
																		item.id,
																})
															)
														}
														inputProps={{
															name: "contract-appendix-number",
														}}
														placeholder="Введите номер клиентского приложения"
													/>

													<div className="adds-files">
														<b>Приложение</b>
														<span>
															Загрузите 1 файл
														</span>

														<InputFile
															name="client-doc2"
															button={true}
														/>
													</div>
												</div>
											</div>
											<hr />
										</div>

										<div className="first-count-steps-wrap">
											<div className="content-form-item-desc">
												<h2>Этапы</h2>
											</div>

											<div className="content-form-item-elems content-form-item-elems--steps">
												<div className="content-form-item-elems-text">
													<p>
														Разделите работы
														отдельными этапами по
														следующей логике:
													</p>
													<ul>
														<li>
															работы по IT: для
															ВЕБ-РЕСУРСА
															(сайт/лендинг/сервис)
															или рекламной
															кампании в сети
															Интернет
														</li>
														<li>
															все остальные
															работы, которые не
															относятся к IT
															(например, дизайн
															для выставочного
															стенда)
														</li>
													</ul>
													<p>
														Каждая работа должна
														быть отдельным этапом.
														Работы по IT нам надо
														выделять отдельно от не
														работ по IT, чтобы мы
														вели корректную
														отчетность по компании.
														Другое (укажите для чего
														своими словами, чтобы
														юрист понял).
													</p>
												</div>

												<div className="first-count-steps">
													{resultSteps}

													<div
														onClick={addStep}
														className="btn-white">
														Добавить этап
													</div>
												</div>
											</div>
										</div>
										<hr />

										{/* <div className="content-form-item content-form-item--column">
											<div className="content-form-item-desc">
												<h2>
													Продукты <i>*</i>
												</h2>
											</div>

											<div
												className="steps"
												data-required={true}>
												{resultProduct}

												<button
													type="button"
													onClick={addProduct}
													className="btn-white">
													Добавить Продукт
												</button>
											</div>
										</div>
										<hr /> */}

										<div className="submit-block">
											<p>
												Подтвердите отправку, нажав
												кнопку «Отправить»
											</p>
											<button
												type="submit"
												className="btn-white btn-white-big">
												Отправить
											</button>
										</div>
									</>
								)}
						</form>
					)}
				</div>

				{isLoading && (
					<div className="layout-fixed">
						<h2>Идёт запрос . . .</h2>
						<img className="loading" src={spinner} alt="Spinner" />
					</div>
				)}
			</div>
		</div>
	);
};

export default FirstCount;
