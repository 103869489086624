import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AuthWrapper = () => {
  const location = useLocation(); // current location

  const token = JSON.parse(localStorage.getItem("token"));

  return token
    ? <Outlet />
    : <>
      {window.localStorage.setItem('startingURL', (location.pathname))}
      (
      <Navigate to="/auth" replace state={{ from: location }} />
      );
    </>
};

export default AuthWrapper;