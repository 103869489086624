import React from 'react'
import useStickyState from "../../assets/js/useStickyState";
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import styles from "./Receipts.scss";

const Receipts = ({ changeTheme, themeBlack }) => {
  return (
    <div>
      <Helmet>
        <title>Поступление денег в отделы</title>
      </Helmet>

      <div className="frame-box">
        <iframe src="https://docs.google.com/spreadsheets/d/1eQKiqpfnBN61jI9gkoBmnLjVd2i53MMja--uIMSVm8w/edit" frameBorder="0"></iframe>
      </div>
    </div>
  )
}

export default Receipts