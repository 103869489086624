function ControlCheckbox(props) {
    let {
        id,
        name,
        onChange,
        type = 'checkbox',
        label,
        className,
        ...rest
    } = props
    const htmlFor = name + (id || Date.now())

    return (
        <label className={`label-checkbox ${className}`}>
            <b>{label}</b>
            <input
                id={htmlFor}
                name={name}
                type={type}
                onChange={e => onChange({
                    name,
                    type,
                    event: e,
                    value: {
                        value: e.target.value,
                        checked: e.target.checked,
                        ...(id !== undefined && {id})
                    }
                })}
                {...rest}
            />
            <span></span>
        </label>
    )
}

export default ControlCheckbox