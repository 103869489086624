import { React, useState, useEffect } from 'react'
import App from '../../App'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import styles from './PlanResult.scss';
import spinner from '../../assets/img/spinner.svg';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const PlanResult = ({ useStickyState, changeTheme, themeBlack }) => {

  const token = JSON.parse(localStorage.getItem("token"));

  const [projectData, setProjectData] = useState('');
  const [passport, setPassport] = useState('');
  const [activePD, setActivePD] = useState(true);
  const [activeP, setActiveP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  function useStickyState(defaultValue, key) {
    const [value, setValue] = useState(() => {
      const stickyValue = window.localStorage.getItem(key);
      return stickyValue !== null
        ? JSON.parse(stickyValue)
        : defaultValue;
    });
    useEffect(() => {
      window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
  }

  const clickBtns = (e) => {
    e.preventDefault();
    if (e.target.innerText === 'Проектные данные (Планы)') {
      setActivePD(true);
      setActiveP(false);
    } else {
      setActivePD(false);
      setActiveP(true);
    }
  }

  const getProjectData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/passport/project-data`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      });

      if (data) {
        console.log('data ', data);
        setProjectData(data.url);
        setIsLoading(false);
        setError(false);
      }

    } catch (error) {
      console.log('error');
      setIsLoading(false);
      setError(true);
    }
  };

  const getPassport = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/passport/passport-data`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      });

      if (data) {
        console.log('data ', data);
        setPassport(data.url);
        setIsLoading(false);
        setError(false);
      }

    } catch (error) {
      console.log('error');
      setIsLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    getProjectData();
    getPassport();
  }, [])

  return (
    <div>
      <Helmet>
        <title>Паспорт и проектные данные</title>
      </Helmet>
      <div className="content content-plan-result">

        {isLoading &&
          <div className="layout-fixed">
            <h2>Идёт запрос . . .</h2>
            <img className="loading" src={spinner} alt="Spinner" />
          </div>
        }

        <div className="container">
          <div className="plan-tabs">
            <a href="#" className={`${activePD ? '_active' : ''} plan-tab`} onClick={clickBtns}>Проектные данные (Планы)</a>
            <a href="#" className={`${activeP ? '_active' : ''} plan-tab`} onClick={clickBtns}>Паспорт (Результаты)</a>
          </div>

          {error && <p>Ошибка при выполнении запроса</p>}


        </div>
      </div>

      <div className="frame-box">
        <div className={`frame-box__item frame-box__item--pd ${activePD ? '_active' : ''}`}>
          <iframe src={projectData} frameBorder="0"></iframe>
        </div>

        <div className={`frame-box__item frame-box__item--p ${activeP ? '_active' : ''}`}>
          <iframe src={passport} frameBorder="0"></iframe>
        </div>
      </div>
    </div>
  )
}

export default PlanResult