import {React, useState, useEffect} from 'react'
import useStickyState from '../../assets/js/useStickyState';
import ReactSelect from 'react-select';
import styles from './ReportsErrors.scss'
import Header from '../../components/Header/Header';
import { Helmet } from 'react-helmet';

const monthSelect = [
    { label: "Январь"},
    { label: "Февраль"},
    { label: "Март"},
    { label: "Апрель"},
    { label: "Май"},
    { label: "Июнь"},
    { label: "Июль"},
    { label: "Август"},
    { label: "Сентябрь"},
    { label: "Октябрь"},
    { label: "Ноябрь"},
    { label: "Декабрь"}
]

const yearSelect = [
    { label: "2017"},
    { label: "2018"},
    { label: "2019"},
    { label: "2020"},
    { label: "2021"},
    { label: "2022"}
]

const ReportsErrors = ({changeTheme, themeBlack}) => {
  return (
    <div>
        <Helmet>
          <title>Отчет по ошибкам в специальности</title>
        </Helmet>
        <div className="content content-reports-errors">
            <div className="container">
                <h1>Отчет по ошибкам в специальности</h1>

                <div className="reports-errors-dates">
                    <div className="support-date">
                        <div className="sub-title">Дата начала:</div>

                        <div className="support-selects">
                            <div className="support-selects__item">
                                <div className="content-select">
                                    <ReactSelect options={monthSelect} className="react-select-container" classNamePrefix="react-select" placeholder='Выберите месяц' />
                                </div>
                            </div>
                            <div className="support-selects__item">
                                <div className="content-select">
                                    <ReactSelect options={yearSelect} className="react-select-container" classNamePrefix="react-select" placeholder='Выберите год' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="support-date">
                        <div className="sub-title">Дата окончания:</div>

                        <div className="support-selects">
                            <div className="support-selects__item">
                                <div className="content-select">
                                    <ReactSelect options={monthSelect} className="react-select-container" classNamePrefix="react-select" placeholder='Выберите месяц' />
                                </div>
                            </div>
                            <div className="support-selects__item">
                                <div className="content-select">
                                    <ReactSelect options={yearSelect} className="react-select-container" classNamePrefix="react-select" placeholder='Выберите год' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <a href="#" className="btn-white">Создать отчет</a>

                <div className="table-errors">
                    <table>
                        <thead>
                            <tr>
                                <th>Проект</th>
                                <th>Задача</th>
                                <th>Кто проставил время</th>
                                <th>Количество времени</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                            <tr>
                                <td>Presentstar — CP Верстка и сборка нового сайта</td>
                                <td><a href="#">Доработки по дизайну</a></td>
                                <td>[ADM] Глеб Гуринович (2449)</td>
                                <td>1.48</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
  )
}

export default ReportsErrors