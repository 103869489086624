import React, { useState, useEffect } from 'react';
import Logout from '../Logout/Logout';
import styles from './Header.scss';
import logo from "../../assets/img/logo.svg";
import logoBlack from "../../assets/img/logo-black.svg";
import arrUp from "../../assets/img/arr-up.svg";
import arrUpBlack from "../../assets/img/arr-up-black.svg";
import arrBlack from "../../assets/img/arr-black.svg";
import arrWhite from "../../assets/img/arr-bottom.svg";
import Nav from '../Nav/Nav';
import useStickyState from "../../assets/js/useStickyState";
import { Link } from 'react-router-dom';

const Header = ({ props, userInfo, permissionsList, rolesList, isBlackChecked, changeTheme, themeBlack }) => {

  const [isShow, setIsShow] = useStickyState(true, 'header-show-status');
  const toggleHeader = () => setIsShow(!isShow);

  const [isHidden, setIsHidden] = useState(false);
  const showHeader = () => { setIsHidden(!isHidden); setIsShow(!isShow); }


  return (
    <header className={!isShow ? "_hide" : "_active"}>
      <button onClick={showHeader} className="btn-show-header">
        <img src={arrBlack} alt="" />
        <img src={arrWhite} alt="" />
      </button>
      <div className="container">
        <div className="header-box">
          <Link to="/" className="logo">
            <img src={logo} alt="" />
            <img src={logoBlack} alt="" />
          </Link>

          <Nav userInfo={userInfo} permissionsList={permissionsList} rolesList={rolesList} />
          <div className="header-info">
            <div className="label-box">
              <span>Перейти на {!themeBlack ? 'тёмную' : 'светлую'} сторону:</span>
              <label>
                <input onChange={changeTheme} type="checkbox" defaultChecked={!themeBlack} />
                <span><i></i></span>
              </label>
            </div>

            <Logout />

            <div onClick={toggleHeader} className="btn-hidden-header">
              <img src={arrUp} alt="" />
              <img src={arrUpBlack} alt="" />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header