import {React, useState, useEffect} from 'react'
import ReactSelect from 'react-select';
import Header from '../../components/Header/Header';
import styles from './ImportInCollab.scss';
import { Helmet } from 'react-helmet';

const ImportInCollab = ({changeTheme, themeBlack}) => {
  return (
    <div>
        <Helmet>
            <title>Импорт времени в коллаб</title>
        </Helmet>
        <div className="content content-import-in-collab">
            <div className="container">
                <h1>Импорт времени в коллаб</h1>
                <form action="">
                    <div className="sub-title">Загрузите файл csv:</div>
                    <label className='input-file'>
                        <input type="file" />
                        <span>Выберите файл +</span>
                    </label>

                    <div className="move-time-btns">
                        <a href="#" className="btn-white">Импортировать</a>
                        <label className='label-checkbox'>
                            <b>Тестовый запуск</b>
                            <input type="checkbox" />
                            <span></span>
                        </label>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default ImportInCollab