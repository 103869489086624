import React from 'react'

function LabelRadio(props) {
    return (
        <label className='label-radio'>
            <b>{props.title}</b>
            <input type="radio"
                onChange={props.changeLabelRadio}
                name={props.name}
                value={props.value}
                />
            <span></span>
        </label>
    )
}

export default LabelRadio