import React from 'react'
import useStickyState from "../../assets/js/useStickyState";
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header'
import styles from './Acts.scss';

const Acts = ({ changeTheme, themeBlack }) => {
  return (
    <div>
      <Helmet>
        <title>Акты</title>
      </Helmet>

      <div className="frame-box">
        <iframe src="https://docs.google.com/spreadsheets/d/17bDrRikl_zMETpGtZvbS4yrn9cAbOK9o5TKJbA-yvZ4/edit#gid=2055772526" frameBorder="0"></iframe>
      </div>
    </div>
  )
}

export default Acts