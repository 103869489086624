import React from 'react'
import useStickyState from "../../assets/js/useStickyState";
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header'

const AssignProduct = ({changeTheme, themeBlack}) => {
  return (
    <div>
      <Helmet>
        <title>Назначить продукт и этап</title>
      </Helmet>
        <div className="content content-external-payment">
            <div className="container">
                <h1>Назначить продукт и этап</h1>
            </div>
        </div>
    </div>
  )
}

export default AssignProduct