import React from "react";

function LabelRadioBtn(props) {
	return (
		<label className={`label-radio-btns ${props.type ? "_label-it" : ""}`}>
			<input
				type="radio"
				className={props.className ? props.className : ""}
				required={props.required}
				defaultChecked={props.defaultChecked}
				onChange={props.changeLabelRadioBtn}
				name={props.name}
				value={props.value}
			/>
			<span>{props.title}</span>
		</label>
	);
}

export default LabelRadioBtn;
