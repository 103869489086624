import React from 'react'

function LabelCheckbox(props) {
    return (
        <label className='label-checkbox'>
            <b>{props.title}</b>
            <input type="checkbox"
                onChange={props.changeLabelCheckbox}
                name={props.name}
                value={props.value}
                />
            <span></span>
        </label>
    )
}

export default LabelCheckbox