import React, { useState } from "react";

const RadioBtnsBlockExecutor = ({
	items,
	chgTypeWorks,
	nameForInput,
	descriptions,
	index,
}) => {
	let title = items.title;

	return (
		<div className="radio-btns__item radio-btns__item--full-width">
			<div className="radio-btns__item-box">
				{items.items.map((item, index) => {
					let itemTitle = item.title || item.name;
					return (
						<label className="label-radio" key={itemTitle}>
							<b>{itemTitle}</b>
							<input
								onChange={chgTypeWorks}
								type="radio"
								name={nameForInput ? nameForInput : title}
								value={item.id}
								data-title={itemTitle}
							/>
							<span></span>
						</label>
					);
				})}
			</div>
			<div
				className="radio-btns__item-desc"
				dangerouslySetInnerHTML={{
					__html: descriptions[index],
				}}></div>
		</div>
	);
};

export default RadioBtnsBlockExecutor;
