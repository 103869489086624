import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import cat from '../../src/assets/img/cat.svg'
import catBlack from '../../src/assets/img/cat-black.svg'
import useSound from 'use-sound';
import myau from '../assets/audio/urr.mp3';
import Lottie from 'lottie-web';
import * as animationDataWhite from '../assets/animations/cat-white.json'
import * as animationDataBlack from '../assets/animations/cat-black.json'
import { useState } from 'react'
import { Helmet } from 'react-helmet';


const Notfoundpage = ({useStickyState,isBlackChecked,themeBlack,changeTheme}) => {
  const [play] = useSound(myau);

  React.useEffect(() => {
    const catWhite = Lottie.loadAnimation({
      container: document.getElementById('catAnimation1'),
      loop: true,
      autoplay: true,
      animationData: animationDataWhite,
      renderer: "svg"
    });

    const catBlack = Lottie.loadAnimation({
      container: document.getElementById('catAnimation2'),
      loop: true,
      autoplay: true,
      animationData: animationDataBlack,
      renderer: "svg"
    });

    return () => {
      catWhite.destroy();
      catBlack.destroy();
    }
  }, [])

  return (
    <div>
        <Helmet>
          <title>Упс, страница не найдена</title>
        </Helmet>
      <div className="content">
          <div className="container">
            <div className="not-found-box">
              <div className="cat-block" onClick={play}>
                <div id="catAnimation1"></div>
                <div id="catAnimation2"></div>
              </div>
              <h1>404</h1>
                <p>Мы не нашли страницу, но посмотрите какой котейка.<br/> Погладьте кота и <span>передохните.</span></p>
                <div className="not-found-btns">
                  <button className="btn-white" onClick={play}>Погладить котика</button>
                  <a href="/">Вернуться на главную</a>
                </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default Notfoundpage