import React from 'react'
import ControlCheckbox from '../ControlCheckbox'

function ControlCheckboxList (props) {

    const handleControlChange = function(controlEvent) {
        props.onChange({
            name: props.name,
            type: 'checkboxList',
            event: controlEvent.event,
            value: props.list.map(item => {
                if (item.id === controlEvent.value.id) {
                    item.checked = controlEvent.event.target.checked
                }
                return item
            })
        })
    }

    return (
        props.list.map(item =>
            <ControlCheckbox
                key={item.id || item.value.toString()}
                id={item.id}
                className={props.className}
                name={props.name}
                label={item.label}
                value={item.value}
                checked={item.checked}
                onChange={handleControlChange}
            />
        )
    )
}

export default ControlCheckboxList