import React from "react";

function LabelRadioBlock(props) {
	return (
		<label className="label-radio label-radio-block">
			<b>{props.title}</b>
			<input
				type="radio"
				onChange={props.changeLabelRadio}
				name={props.name}
				value={props.value}
			/>
			<span></span>
			<i></i>
			<div
				dangerouslySetInnerHTML={{
					__html: props.hint,
				}}></div>
		</label>
	);
}

export default LabelRadioBlock;
