import React from 'react'
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header'
import styles from './Plans.scss';

const PlanRd = ({ changeTheme, themeBlack }) => {
  return (
    <div>
      <Helmet>
        <title>Финансовое планирование RD</title>
      </Helmet>

      <div className="frame-box">
        <iframe src="https://docs.google.com/spreadsheets/d/1nN0AFxDqkGkJQoPpJglMaiTfJdvx9Xr8-ZqsR5oiQpY" frameBorder="0"></iframe>
      </div>
    </div>
  )
}

export default PlanRd