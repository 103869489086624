import React from 'react'
import styles from './IncomingCalls.scss'
import ReactSelect from 'react-select';
import { Helmet } from 'react-helmet';
import ContactForm from '../../components/ContactForm/ContactForm';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import successImage from '../../assets/img/success.svg'
import successImageBlack from '../../assets/img/success-black.svg'

const IncomingCalls = () => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  const productType = [
    { value: 'Не уточнен', label: "Не уточнен" },
    { value: 'Корп. сайт', label: "Корп. сайт" },
    { value: 'Сайт-каталог', label: "Сайт-каталог" },
    { value: 'Сайт ЖК', label: "Сайт ЖК" },
    { value: 'Интернет-магазин', label: "Интернет-магазин" },
    { value: 'Другие сайты', label: "Другие сайты" },
    { value: 'Сервисный дизайн', label: "Сервисный дизайн" },
    { value: 'Лендинг', label: "Лендинг" },
    { value: 'Брендинг', label: "Брендинг" },
    { value: 'Платформа бренда', label: "Платформа бренда" },
    { value: 'Айдентика', label: "Айдентика" },
    { value: 'Граф.дизайн', label: "Граф.дизайн" },
    { value: 'Key-visual и иллюстрации', label: "Key-visual и иллюстрации" },
    { value: 'Развитие сайта', label: "Развитие сайта" },
    { value: 'Аналитика и исследования', label: "Аналитика и исследования" },
    { value: 'Комплексная поддержка', label: "Комплексная поддержка" },
    { value: 'Спецпроект digital', label: "Спецпроект digital" },
    { value: 'Видео', label: "Видео" },
    { value: 'Креатив и реклама', label: "Креатив и реклама" },
    { value: 'SMM', label: "SMM" },
    { value: 'Промо-сайт', label: "Промо-сайт" },
    { value: 'Прочее', label: "Прочее" }
  ]

  // useEffect(() => {
  //   const fetchToken = async () => {
  //     try {
  //       const { data } = await axios({
  //         method: 'GET',
  //         url: `${process.env.REACT_APP_BACKEND_URL}/v1/user`,
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Accept': 'application/json',
  //           'Authorization': 'Bearer ' + token,
  //         }
  //       });

  //       if (data) {
  //         console.log('Пользователь авторизован');
  //       }
  //     } catch (error) {
  //       console.log('Срок действия токена истёк или токен неправильный');
  //       navigate('/auth');
  //     }
  //   }
  //   fetchToken()
  // }, [])

  const [succsessForm, setSuccsessForm] = useState(null);
  const [err, SetErr] = useState(false);

  const firstNameRef = React.useRef();
  const lastNameRef = React.useRef();
  const companyRef = React.useRef();
  const emailRef = React.useRef();
  const phoneRef = React.useRef();
  const productRef = React.useRef();
  const commentRef = React.useRef();

  const onBlur = (e) => {
    if (e.target.value === 0) {
      SetErr(true);
    }
  }

  const onChange = (e) => {
    if (e.value !== 0) {
      SetErr(false);
    }
  }

  function handlerIncCall(e) {
    e.preventDefault();
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    const company = companyRef.current.value;
    const email = emailRef.current.value;
    const phone = phoneRef.current.value;
    const product = productRef.current.props.value.value;
    const comment = commentRef.current.value;
    const IncCallData = JSON.stringify({ "first-name": firstName !== 0 && firstName, "last-name": lastName !== 0 && lastName, "company": company !== 0 && company, "email": email !== 0 && email, "phone": phone !== 0 && phone, "product": product !== null && product, "comment": comment !== 0 && comment });

    if (!product) {
      SetErr(true);
    } else {
      const fetchData = async () => {
        try {
          const { data } = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BACKEND_URL}/v1/incoming-call`,
            data: IncCallData,
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              'Authorization': 'Bearer ' + token
            }
          });

          if (data) {
            setSuccsessForm(true);
            console.log('Успешно отправлено!');
          }
        } catch (error) {
          setSuccsessForm(false);
          console.log('Ошибка - ' + error.message);
        }
      }
      fetchData()
    }
  }

  return (
    <div>
      <Helmet>
        <title>Входящие звонки</title>
      </Helmet>
      <div className="content content-incoming-calls">
        <div className="container">

          {
            !succsessForm &&
            <>
              <h1>Входящие звонки</h1>
              <div className="desc">Все поля помеченные <i>*</i> обязательны для заполнения</div>
            </>
          }

          {
            !succsessForm &&
            <form onSubmit={handlerIncCall} action={process.env.REACT_APP_BACKEND_URL + '/v1/incoming-call'} className='form-calls' method='POST'>
              <input ref={firstNameRef} type="text" name='first-name' required placeholder='Имя звонившего*' />
              <input ref={lastNameRef} type="text" name='last-name' placeholder='Фамилия звонившего' />
              <input ref={companyRef} type="text" name='company' required placeholder='Организация*' />
              <input ref={emailRef} type="email" name='email' placeholder='Электронная почта' />
              <input ref={phoneRef} type="tel" name='phone' required placeholder='Телефон*' />

              <div className="form-calls-select">
                <p>Первичный продукт* {err && <span className="error">Заполните это поле</span>}</p>
                <div className="content-select">
                  <ReactSelect onChange={onChange} onBlur={onBlur} aria-required="true" defaultValue="0" ref={productRef} required="required" name="product" options={productType} className="react-select-container" classNamePrefix="react-select" placeholder="Введите название" />
                </div>
              </div>

              <div className="form-calls-textarea">
                <p>Примечания</p>
                <textarea ref={commentRef} required name="comment" placeholder='Например, кто принял звонок?*'></textarea>
              </div>

              <button type='submit' className='btn-white'>Отправить в отдел продаж</button>
            </form>
          }

          {
            succsessForm && succsessForm != null &&
            <div className='form-call-message _success'>
              <img class="_dark" src={successImage} alt="Success" />
              <img class="_light" src={successImageBlack} alt="Success" />
              <div class="form-call-message__title">Готово!</div>
              <p>Данные отправлены</p>
              <a href="/" className="btn-white">Вернуться на главную</a>
            </div>
          }

          {
            !succsessForm && succsessForm != null &&
            <div className='form-call-message'>
              <h2>Ошибка при отправке данных, попробуйте снова.</h2>
            </div>
          }

        </div>
      </div>
    </div>
  )
}

export default IncomingCalls