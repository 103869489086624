import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import styles from './FinansistProjectExport.scss'
import axios from 'axios'
import spinner from '../../assets/img/spinner.svg';

const FinansistProjectExport = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [messageRequest, setMessageRequest] = useState('');
    const token = JSON.parse(localStorage.getItem("token"));

    const runExportProjects = () => {
        setIsLoading(true);
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}/v1/fin-sync`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(function (response) {
                setMessageRequest(response.data.message);
                setIsLoading(false);
            })
            .catch(function () {
                console.log('error');
                setIsLoading(false);
            });
    }

    return (
        <div>
            <Helmet>
                <title>Экспорт проектов из коллаба в Финансист</title>
            </Helmet>

            {isLoading &&
                <div className="layout-fixed">
                    <h2>Подождите, идёт эскпорт...</h2>
                    <img className="loading" src={spinner} alt="Spinner" />
                </div>
            }

            <div className="content content-export-projects">
                <div className="container">
                    <h1>Экспорт проектов из коллаба в Финансист</h1>
                    <div className="desc">Выгрузка происходит автоматически 1 раз в час, но вы, также, можете запустить её вручную, нажав на кнопку ниже:</div>

                    <button onClick={runExportProjects} className="btn-white">Запустить</button>
                    <p>{messageRequest}</p>
                </div>
            </div>
        </div>
    )
}

export default FinansistProjectExport