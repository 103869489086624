export const inputRequired = ({value}) => {
    return !value ? 'Поле не заполнено' : null
}

export const inputArrayRequired = (stateinputArray) => {
    return !stateinputArray.some(data => data.value) ? 'Нужно заполнить минимум одно поле' : null
}

export const checkboxRequired = ({checked}) => {
    return !checked ? 'Поле обязательно' : null
}

export const inputPhone = ({value}) => {
    return !/\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/.test(value) ? 'Некорректный телефон' : null
}

export const inputPhoneRequired = ({value}) => {
    return !/\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/.test(value) ? value === '+7 (___) ___-__-__' ? 'Поле не заполнено' : 'Некорректный телефон' : null
}
