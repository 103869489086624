import React from 'react'
import { Link } from 'react-router-dom'
import noAccessImg from '../../src/assets/img/no-access-img.svg'
import noAccessImgBlack from '../../src/assets/img/no-access-img-black.svg'
import noAccessImg2 from '../../src/assets/img/no-access-img2.svg'
import noAccessImg2Black from '../../src/assets/img/no-access-img2-black.svg'
import arm1 from '../../src/assets/img/arm-1.png'
import arm1Black from '../../src/assets/img/arm-1-black.png'
import arm2 from '../../src/assets/img/arm-2.png'
import arm2Black from '../../src/assets/img/arm-2-black.png'
import { Helmet } from 'react-helmet';

const NoAccessPage = () => {
  return (
    <div>
      <Helmet>
        <title>Нет доступа</title>
      </Helmet>
      <div className="content content-no-access">
        <div className="container">
          <div className="no-access-box">
            <div className="no-access-box-imgs">
              <div className="no-access-box-imgs__left">
                <i></i>
                <div className="img-circle-box">
                  <img className="img-circle" src={noAccessImg} alt="" />
                  <img className="img-circle" src={noAccessImgBlack} alt="" />
                </div>
                <img className="arm" src={arm1} alt="" />
                <img className="arm" src={arm1Black} alt="" />
              </div>
              <div className="no-access-box-imgs__right">
                <i></i>
                <div className="img-circle-box">
                  <img className="img-circle" src={noAccessImg2} alt="" />
                  <img className="img-circle" src={noAccessImg2Black} alt="" />
                </div>
                <img className="arm" src={arm2} alt="" />
                <img className="arm" src={arm2Black} alt="" />
              </div>
            </div>
            <div className="no-access-box-text">
              <p>К сожалению, у вас <b>нет доступа</b> к этой странице. Свяжитесь с <span>Лешей</span> или <span>Глебом</span> для решения проблемы.</p>
              <a href="/" className="btn-white">На главную</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoAccessPage