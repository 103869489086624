import React, { useState } from "react";
import closeGreen from "../../assets/img/close-green.svg";

const FirstCountStep = ({
	num,
	removeSteps,
	data,
	dataTypes,
	stagesOther,
	input,
	showLabelRadioBtnTypes,
	showLabelRadioBtnSteps,
}) => {
	let stepItemsName = "stage-item_" + num;
	let stepTypesName = "stage-type_" + num;
	// let currStage = document.querySelector(".step-works-" + num);
	// console.log("currStage", currStage);
	// let isOtherTrue = currStage.classList.contains("_show-other");

	return (
		<div className="first-count-step _no-checked-step">
			{num != 1 && (
				<div
					onClick={() => removeSteps(num - 1)}
					id={num}
					className="delete-step">
					Удалить этап{" "}
					<i>
						<img src={closeGreen} alt="" />
					</i>
				</div>
			)}
			<b>Этап №{num}</b>

			<div className="total-step-type">
				<div className="label-radio-btns-wrap">
					{showLabelRadioBtnTypes(
						dataTypes,
						stepTypesName,
						"stage-type"
					)}
				</div>
			</div>

			<div
				className={`step-works content-form-item step-works-${num} _it`}>
				<div className="label-radio-btns-wrap" data-required={true}>
					{showLabelRadioBtnSteps(data, stepItemsName, stepItemsName)}
				</div>

				{input["stage-item_" + num + "Id" + num] === "s16" && (
					<input
						className="other-input"
						placeholder={stagesOther.hint}
						name="other"
					/>
				)}

				{/* <input
					className="other-input"
					placeholder={stagesOther.hint}
					name="other"
				/> */}
			</div>
		</div>
	);
};

export default FirstCountStep;
