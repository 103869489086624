import axios from "axios";
import { useQuery, QueryClient } from "@tanstack/react-query";

const useCreationData = () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const queryClient = new QueryClient();

	const { data: creationData, status: creationDataStatus } = useQuery({
		queryKey: ["creationData"],
		queryFn: async () => {
			const { data } = await axios({
				method: "GET",
				url: `${process.env.REACT_APP_BACKEND_URL}/v1/documents/create/fields`,
				// url: "../ajax/at2-general.json",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
			});

			return data;
		},
	});

	return {
		creationData,
		creationDataStatus,
	};
};

export default useCreationData;
