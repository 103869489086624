import React, { useState, useEffect } from "react";
import UseCreationData from "../../api/UseCreationData";

import RadioBtnsBlock from "../../components/RadioBtnsBlock/RadioBtnsBlock";
import Datepicker from "../../components/Datepicker/Datepicker";
import Stage from "../../components/Stage/Stage";

import closeGreen from "../../assets/img/close-green.svg";
import clear from "../../assets/img/clear.svg";

const Application = ({
	id,
	remove,
	input,
	department,
	lineOfWork,
	setInput,
	hasTask,
	workDescriptionDetail,
	applications,
	applicationsExecutor,
	applicationsMarketing,
	applicationsDepartment,
	taskForm,
	showLabelRadio,
	showLabelRadioBlock,
	showLabelRadioBtn,
	showLabelCheckbox,
	changeLabelRadio,
	chgDate,
	applicationInput,
	applicationsInGroups,
	showApplicationsGroups,
	showApplicationsGroupsNoDepartment,
	setErrors,
}) => {
	// Этапы работы (Новый договор, приложение)
	const [itemsStage, setItemsStage] = useState([""]);
	const chgSteps = function (event, stageNumber) {
		let stagesArr = input[`stages${id}`] || [];
		let stageInfo = stagesArr[stageNumber] || {};
		stageInfo[event.target.name] = event.target.value;
		stagesArr[stageNumber] = stageInfo;

		setErrors(event.target.value, event.target.name);
		applicationInput(`stages${id}`, stagesArr);
	};
	function addStage(index) {
		setItemsStage([...itemsStage, index.target.value]);
	}
	function removeStage(index) {
		setItemsStage([
			...itemsStage.slice(0, index),
			...itemsStage.slice(index + 1),
		]);
	}
	const resultStages = itemsStage.map((element, index) => {
		return (
			<Stage
				id={index + 1}
				appId={id}
				key={index}
				chgSteps={(e) => chgSteps(e, index)}
				remove={removeStage}
			/>
		);
	});

	let groupsFixPriceDecriptions = [
		"<p>Для формулировки предмета работ нужно <span>указать URL или название продукта.</span></p><p>Например: Разработка дизайна сайта www.name.ru или разработка дизайна сайта для лендинга под названием «Печенье Любятово».</p><p>В продукт “Промо-сайт” входят лендинги с креативом (креатив -  этап работ в составе продукта)</p>",
		"<p>Определитесь,  для чего вы делаете креатив/видео:<ol><li><span>Для размещения на веб-продукте</span> (сайт/лендинг/другой веб-сервис) под определнным URL или названием “__________”. В таком случае, укажите урл или название веб-продукта.</li><li><span>Для рекламной кампании в Сети интернет</span> под названием  “________” . Тогда укажите название рекламной кампании.</li><li><span>Другое</span> (укажите для чего своими словами, чтобы юрист понял. Например: для трансляции на экранах в офисе Заказчика)</li></p>",
		"",
		"",
	];

	let groupsSupportHoursDecriptions = [
		"<p>Это поддержка, направленная на улучшение/поддержание конкретного веб-продукта или продуктов, например:</p><ul><li>разработка иллюстраций для Сайта</li><li>разработка 2d/3d иконок/изображений для Сайта</li><li>видео для Сайта</li><li>ретушь фотографий Сайта</li><li>копирайтинг для Сайта</li><li>разработка/доработка функционала  для Сайта</li><li>разработка дизайна страниц/блоков на странице для Сайта</li></ul>",
		"<p>Это работы по созданию <span>только</span> диджитал-продуктов <span>для рекламной кампании</span> в сети Интернет. Это следующие работы:</p><ul><li>разработка email-писем (дизайн и разработка) для рекламной кампании</li><li>разработка баннеров для рекламной кампании</li><li>разработка креативных концепций</li><li>разработка копирайтинга</li><li>разработка кейвижуала</li><li>разработка 2d/3d иллюстраций</li><li>разработка видео</li></ul>",
		"<p>Это работы по созданию оффлайн-носителей для бренда (каталоги/лифлеты/презентации)</p>",
		"",
	];

	return (
		<>
			{/*Какой отдел будет выполнять/заказывать работу по приложению?*/}
			<div className="content-form-item-wrap content-form-item-wrap-app">
				<h2>Приложение {id}</h2>
				{id !== 1 && (
					<div
						onClick={() => remove(id - 1)}
						id={id}
						className="delete-step">
						Удалить приложение{" "}
						<i>
							<img src={closeGreen} alt="" />
						</i>
					</div>
				)}
				{input.contractSignerId === "cs1" && (
					<div>
						<div className="content-form-item">
							<div className="content-form-item-desc">
								<label htmlFor="">
									{department.title}{" "}
									{department.required && <i>*</i>}
								</label>
							</div>

							<div
								className="label-radio-btns-wrap"
								data-required={
									department.required ? true : false
								}>
								{showLabelRadioBtn(
									department,
									`apps-${id}_department`,
									`department${id}`
								)}
							</div>
						</div>
						<hr />
					</div>
				)}
			</div>

			{/* {
				// Выберите направление работ
				input[`department${id}`] === "Производственный отдел" &&
					input[`department${id}`] != null &&
					input[`department${id}`] != "" && (
						<>
							<div className="content-form-item">
								<div className="content-form-item-desc">
									<label htmlFor="">
										{lineOfWork.title}{" "}
										{lineOfWork.required && <i>*</i>}
									</label>
								</div>

								<div
									className="label-radio-btns-wrap"
									data-required={
										lineOfWork.required ? true : false
									}>
									{showLabelRadioBtn(
										lineOfWork,
										`apps-${id}_line-of-work`,
										"lineOfWork"
									)}
								</div>
							</div>
							<hr />
						</>
					)
			} */}

			{
				// часть формы когда выбрано что мы Исполнитель
				input.contractSignerId === "cs2" && (
					<>
						<div className="content-form-item content-form-item--full-width">
							<div className="content-form-item-desc">
								<label htmlFor="">
									{applicationsExecutor["type-works"].title}{" "}
									{applicationsExecutor["type-works"]
										.required && <i>*</i>}
								</label>
							</div>

							<div
								className="label-radio-btns-wrap label-radio-btns-wrap--full-width"
								data-required={
									applicationsExecutor["type-works"].required
										? true
										: false
								}>
								{showLabelRadioBlock(
									applicationsExecutor["type-works"],
									`apps-${id}_type-works`,
									`typeWorks${id}`
								)}
							</div>
						</div>
						<hr />
					</>
				)
			}

			{
				// Разовые работы по Fix price
				input[`typeWorks${id}Id`] === "tw1" && (
					<>
						<div className="content-form-item content-form-item--full-width">
							<div className="content-form-item-desc">
								<label htmlFor="">
									{
										applicationsExecutor[
											"application-subject-fix-price"
										].title
									}{" "}
									{applicationsExecutor[
										"application-subject-fix-price"
									].required && <i>*</i>}
								</label>
							</div>

							<div
								className="label-radio-btns-wrap label-radio-btns-wrap--full-width"
								data-required={
									applicationsExecutor[
										"application-subject-fix-price"
									].required
										? true
										: false
								}>
								{showApplicationsGroupsNoDepartment(
									applicationsExecutor,
									applicationsInGroups(
										applicationsExecutor[
											"application-subject-fix-price"
										].options
									),
									`application-subject-fix-price`,
									`apps-${id}_`,
									`applicationSubjectFixPrice${id}`,
									groupsFixPriceDecriptions
								)}
							</div>
						</div>

						<div className="content-form-item content-form-item--full-width">
							<div
								className="textarea-block-wrap"
								data-required="true">
								<div className="textarea-block textarea-block-full-width">
									<textarea
										name={`apps-${id}_application-subject-description-work`}
										placeholder={
											applicationsExecutor[
												"application-subject-description-work"
											].title
										}
										onChange={(e) =>
											setInput((prev) => ({
												...prev,
												applicationSubjectDescriptionWork:
													e.target.value,
											}))
										}></textarea>
								</div>
							</div>
						</div>
						<hr />
					</>
				)
			}

			{
				// Поддержка по часам на основании ежемесячного отчета
				input[`typeWorks${id}Id`] === "tw2" && (
					<>
						<div className="content-form-item content-form-item--full-width">
							<div className="content-form-item-desc">
								<label htmlFor="">
									{
										applicationsExecutor[
											"application-subject-support-hours"
										].title
									}{" "}
									{applicationsExecutor[
										"application-subject-support-hours"
									].required && <i>*</i>}
								</label>
							</div>

							<div
								className="label-radio-btns-wrap label-radio-btns-wrap--full-width"
								data-required={
									applicationsExecutor[
										"application-subject-support-hours"
									].required
										? true
										: false
								}>
								{showApplicationsGroupsNoDepartment(
									applicationsExecutor,
									applicationsInGroups(
										applicationsExecutor[
											"application-subject-support-hours"
										].options
									),
									`application-subject-support-hours`,
									`apps-${id}_`,
									`applicationSubjectSupportHours${id}`,
									groupsSupportHoursDecriptions
								)}
							</div>
						</div>

						<div className="content-form-item content-form-item--full-width">
							<div
								className="textarea-block-wrap"
								data-required="true">
								<div className="textarea-block textarea-block-full-width">
									<textarea
										name={`apps-${id}_application-subject-description-work`}
										placeholder={
											applicationsExecutor[
												"application-subject-description-work"
											].title
										}
										onChange={(e) =>
											setInput((prev) => ({
												...prev,
												applicationSubjectDescriptionWork:
													e.target.value,
											}))
										}></textarea>
								</div>
							</div>
						</div>
						<hr />
					</>
				)
			}

			{
				// Приложение на много разных работ с фиксированной стоимостью или по рейт-карте
				input[`typeWorks${id}Id`] === "tw3" && (
					<>
						<div className="content-form-item content-form-item--full-width">
							<div className="content-form-item-desc">
								<label htmlFor="">
									{
										applicationsExecutor[
											"application-subject-many-differents-work"
										].title
									}{" "}
									{applicationsExecutor[
										"application-subject-many-differents-work"
									].required && <i>*</i>}
								</label>
								<div
									dangerouslySetInnerHTML={{
										__html: applicationsExecutor[
											"application-subject-many-differents-work"
										].hint,
									}}></div>
							</div>
						</div>

						<div className="content-form-item content-form-item--full-width">
							<div
								className="textarea-block-wrap"
								data-required="true">
								<div
									className="textarea-block textarea-block-full-width"
									data-required={
										applicationsExecutor[
											"application-subject-many-differents-work"
										].required
											? true
											: false
									}>
									<textarea
										name={`apps-${id}_application-subject-many-differents-work`}
										placeholder="Подробное описание работ или услуг"
										onChange={(e) =>
											setInput((prev) => ({
												...prev,
												applicationSubjectManyDifferentsWork:
													e.target.value,
											}))
										}></textarea>
								</div>
							</div>
						</div>
						<hr />
					</>
				)
			}

			{
				// Веб-продукт (дизайн или под ключ) по t&m
				input[`typeWorks${id}Id`] === "tw4" && (
					<>
						<div className="content-form-item content-form-item--full-width">
							<div className="content-form-item-desc">
								<label htmlFor="">
									{
										applicationsExecutor[
											"application-subject-web-product"
										].title
									}{" "}
									{applicationsExecutor[
										"application-subject-web-product"
									].required && <i>*</i>}
								</label>
								<div
									dangerouslySetInnerHTML={{
										__html: applicationsExecutor[
											"application-subject-many-differents-work"
										].hint,
									}}></div>
							</div>
						</div>

						<div className="content-form-item content-form-item--full-width">
							<div
								className="textarea-block-wrap"
								data-required="true">
								<div
									className="textarea-block textarea-block-full-width"
									data-required={
										applicationsExecutor[
											"application-subject-web-product"
										].required
											? true
											: false
									}>
									<textarea
										name={`apps-${id}_application-subject-web-product`}
										placeholder="Подробное описание работ или услуг"
										onChange={(e) =>
											setInput((prev) => ({
												...prev,
												applicationSubjectWebProduct:
													e.target.value,
											}))
										}></textarea>
								</div>
							</div>
						</div>
						<hr />
					</>
				)
			}

			{
				//Предмет приложения
				input[`department${id}`] != null &&
					input[`department${id}`] != "" &&
					input.contractSignerId === "cs1" && (
						<>
							<div
								className={`content-form-item content-form-item-application-subject content-form-item--full-width ${
									input[`department${id}`] ==
									"Производственный отдел"
										? "content-form-item-row"
										: ""
								}`}>
								<div className="content-form-item-desc">
									<label htmlFor="">
										{
											applications[
												`application-subject-${
													input[`department${id}Id`]
												}`
											].title
										}{" "}
										{applications[
											`application-subject-${
												input[`department${id}Id`]
											}`
										].required && <i>*</i>}
									</label>
									{input[`department${id}`] ==
										"Производственный отдел" && (
										<div
											dangerouslySetInnerHTML={{
												__html: applicationsDepartment.hint,
											}}></div>
									)}
								</div>

								<div
									className="whats-do-wrap"
									data-required={
										applications[
											`application-subject-${
												input[`department${id}Id`]
											}`
										].required
											? true
											: false
									}>
									<div className="whats-do-list">
										{/* {showApplicationsGroups(
											applications,
											applicationsInGroups(
												applicationsMarketing[
													input[`department${id}Id`]
												]
											),
											input[`department${id}Id`],
											`application-subject`,
											`apps-${id}_`,
											`applicationSubject${id}`
										)} */}

										{input[`department${id}`] ==
											"Отдел маркетинга" &&
											showLabelRadio(
												applicationsMarketing,
												`apps-${id}_application-subject-marketing`,
												`applicationSubject${id}`
											)}

										{((input[`department${id}`] ==
											"Отдел маркетинга" &&
											input[`applicationSubject${id}`] ===
												"Другое") ||
											(input[`department${id}Id`] ===
												"development" &&
												input[
													`applicationSubject${id}Id`
												] === "asd45") ||
											(input[`department${id}Id`] ===
												"digital" &&
												input[
													`applicationSubject${id}Id`
												] === "asd11")) && (
											<div className="other-input">
												<input
													onChange={() =>
														applicationInput(
															`applicationSubject${id}Other`,
															"Другое"
														)
													}
													name={`apps-${id}_application-subject-${
														input[
															`department${id}Id`
														]
													}-other`}
													type="text"
													placeholder="Другое"
												/>
											</div>
										)}

										{input[`department${id}`] ==
											"Производственный отдел" && (
											<div
												className="textarea-block"
												data-required={
													applicationsDepartment.required
														? true
														: false
												}
												onChange={(e) =>
													setInput((prev) => ({
														...prev,
														appsApplicationSubjectDevelopmentDescription:
															e.target.value,
													}))
												}>
												<textarea
													name={`apps-${id}_application-subject-development`}
													placeholder="Введите описание"></textarea>
											</div>
										)}
									</div>
								</div>
							</div>
							<hr />
						</>
					)
			}

			{
				// Есть ли у Вас задание
				((input[`department${id}`] == "Производственный отдел" &&
					input.appsApplicationSubjectDevelopmentDescription !=
						null &&
					input.appsApplicationSubjectDevelopmentDescription != "") ||
					(input[`applicationSubjectFixPrice${id}`] != "" &&
						input[`applicationSubjectFixPrice${id}`] != null &&
						input.applicationSubjectDescriptionWork != "" &&
						input.applicationSubjectDescriptionWork != null) ||
					(input[`applicationSubjectSupportHours${id}`] != "" &&
						input[`applicationSubjectSupportHours${id}`] != null &&
						input.applicationSubjectDescriptionWork != "" &&
						input.applicationSubjectDescriptionWork != null) ||
					(input.applicationSubjectManyDifferentsWork != "" &&
						input.applicationSubjectManyDifferentsWork != null) ||
					(input.applicationSubjectWebProduct != "" &&
						input.applicationSubjectWebProduct != null)) && (
					<>
						<div className="content-form-item">
							<div className="content-form-item-desc">
								<label htmlFor="">
									{hasTask.title}{" "}
									{hasTask.required && <i>*</i>}
								</label>
							</div>

							<div
								className="label-radio-btns-wrap"
								data-required={hasTask.required ? true : false}>
								{showLabelRadioBtn(
									hasTask,
									`apps-${id}_has-task`,
									`hasTask${id}`
								)}
							</div>
						</div>
						<hr />
					</>
				)
			}

			{/* {input[`department${id}`] == "Производственный отдел" &&
				input.hasTask != null &&
				input.hasTask == "Да" && (
					<>
						<p>11111</p>
						<hr />
					</>
				)
			} */}

			{
				//Подробное описание работ
				input[`department${id}`] != "Производственный отдел" &&
					input[`applicationSubject${id}`] != null &&
					input[`applicationSubject${id}`] != "" && (
						<>
							<div className="content-form-item  content-form-item--full-width">
								<div className="content-form-item-desc">
									<label htmlFor="">
										{
											applications[
												"application-subject-marketing-description-work"
											].title
										}{" "}
										{applications[
											"application-subject-marketing-description-work"
										].required && <i>*</i>}
									</label>
									<p>
										{
											applications[
												"application-subject-marketing-description-work"
											].hint
										}{" "}
									</p>
								</div>

								{/* <div
									className="content-select"
									data-required={
										applications[
											"application-subject-marketing-description-work"
										].required
											? true
											: false
									}>
									<input
										onChange={(e) =>
											applicationInput(
												"workDescriptionDetail",
												e.target.value
											)
										}
										name={`apps-${id}_application-subject-marketing-description-work`}
										type="text"
										placeholder="Описание работ"
									/>
								</div> */}

								<div className="textarea-block textarea-block-full-width">
									<textarea
										onChange={(e) =>
											applicationInput(
												"workDescriptionDetail",
												e.target.value
											)
										}
										name={`apps-${id}_application-subject-marketing-description-work`}
										placeholder="Описание работ"></textarea>
								</div>
							</div>
							<hr />
						</>
					)
			}

			{
				//Дата начала первого отчетного периода
				input[`applicationSubject${id}`] != null &&
					input[`applicationSubject${id}`] != "" &&
					((input[`department${id}Id`] === "development" &&
						(input[`applicationSubject${id}Id`] === "asd15" ||
							input[`applicationSubject${id}Id`] === "asd23" ||
							input[`applicationSubject${id}Id`] === "asd34")) ||
						(input[`department${id}Id`] === "digital" &&
							(input[`applicationSubject${id}Id`] === "asd4" ||
								input[`applicationSubject${id}Id`] ===
									"asd5"))) && (
						<>
							<div className="content-form-item">
								<div className="content-form-item-desc">
									<label htmlFor="">
										{
											applications[
												`application-subject-${
													input[`department${id}Id`]
												}-monthly`
											].title
										}{" "}
										<i>*</i>
									</label>
								</div>

								<div
									className="content-select"
									data-required={true}>
									<Datepicker
										name={`apps-${id}_application-subject-${
											input[`department${id}Id`]
										}-monthly`}
										inputName={`applicationSubjectMonthly${id}`}
										chgDate={chgDate}
									/>
								</div>
							</div>
							<hr />
						</>
					)
			}

			{
				//В каком виде будет Задание?
				((input[`department${id}`] == "Производственный отдел" &&
					input[`hasTask${id}`] != null &&
					input[`hasTask${id}`] == "Да") ||
					(input.contractSignerId === "cs2" &&
						input[`hasTask${id}`] != null &&
						input[`hasTask${id}`] == "Да")) && (
					<>
						<div className="content-form-item">
							<div className="content-form-item-desc">
								<label htmlFor="">
									{taskForm.title}{" "}
									{taskForm.required && <i>*</i>}
								</label>
							</div>

							<div
								className="content-form-item-right"
								data-required={
									taskForm.required ? true : false
								}>
								{showLabelRadio(
									taskForm,
									`apps-${id}_task-form`,
									`taskForm${id}`
								)}
							</div>
						</div>
						<hr />
					</>
				)
			}

			{
				//Этапы
				((input[`department${id}`] == "Отдел маркетинга" &&
					input.workDescriptionDetail != null &&
					input.workDescriptionDetail != "") ||
					(input[`department${id}`] == "Производственный отдел" &&
						input[`hasTask${id}`] == "Нет") ||
					(input[`department${id}`] == "Производственный отдел" &&
						input[`taskForm${id}`] != null &&
						input[`taskForm${id}`] != "") ||
					(input.contractSignerId === "cs2" &&
						input[`taskForm${id}`] != null &&
						input[`taskForm${id}`] != "" &&
						input.typeWorksId != "tw2") ||
					(input.contractSignerId === "cs2" &&
						input[`hasTask${id}`] == "Нет" &&
						input.typeWorksId != "tw2")) && (
					<>
						<div className="content-form-item content-form-item--full-width">
							<div className="content-form-item-desc">
								<label htmlFor="">
									Этапы <i>*</i>
								</label>
							</div>

							<div className="steps" data-required={true}>
								{resultStages}
								<button
									type="button"
									onClick={addStage}
									className="btn-white">
									Добавить этап
								</button>
							</div>
						</div>
						<hr />

						{
							//Будет ли закрываться каждый этап актом
							input[`stages${id}`] != null &&
								input[`stages${id}`][0] != null &&
								Object.keys(input[`stages${id}`][0]).length >=
									3 && (
									<>
										<div className="content-form-item content-form-item--full-width">
											<div className="content-form-item-desc">
												<label htmlFor="">
													{
														applications[
															"closed-one-act"
														].title
													}{" "}
													{applications[
														"closed-one-act"
													].required && <i>*</i>}
												</label>
											</div>

											<div
												className="whats-do-wrap"
												data-required={
													applications[
														"closed-one-act"
													].required
														? true
														: false
												}>
												<div className="whats-do-list">
													{showLabelRadio(
														applications[
															"closed-one-act"
														],
														`apps-${id}_closed-one-act`,
														`closedOneAct${id}`
													)}
												</div>

												{(input[`closedOneAct${id}`] ===
													"coa3" ||
													input[
														`closedOneAct${id}`
													] === "Другое") && (
													<div className="whats-do-adds">
														<input
															onChange={(e) =>
																applicationInput(
																	`closedOneActOther${id}`,
																	e.target
																		.value
																)
															}
															name={`apps-${id}_closed-one-act-other`}
															type="text"
															placeholder="Другое"
														/>
													</div>
												)}
											</div>
										</div>
										<hr />
									</>
								)
						}

						{
							//Последовательность выполнения работ
							((input[`closedOneAct${id}`] != null &&
								input[`closedOneAct${id}`] != "" &&
								input[`closedOneAct${id}`] != "Другое") ||
								(input[`closedOneAct${id}`] != null &&
									input[`closedOneAct${id}`] != "" &&
									input[`closedOneAct${id}`] === "Другое" &&
									input[`closedOneActOther${id}`] != null &&
									input[`closedOneActOther${id}`].length >
										0)) && (
								<>
									<div className="content-form-item content-form-item--full-width">
										<div className="content-form-item-desc">
											<label htmlFor="">
												{
													applications[
														"work-sequence"
													].title
												}{" "}
												{applications["work-sequence"]
													.required && <i>*</i>}
											</label>
										</div>

										<div
											className="whats-do-wrap"
											data-required={
												applications["work-sequence"]
													.required
													? true
													: false
											}>
											<div className="whats-do-list">
												{showLabelRadio(
													applications[
														"work-sequence"
													],
													`apps-${id}_work-sequence`,
													`workSequence${id}`
												)}
											</div>

											{input[`workSequence${id}Id`] ===
												"ws2" && (
												<div className="whats-do-adds">
													<input
														onChange={(e) =>
															applicationInput(
																`workSequenceExecution${id}`,
																e.target.value
															)
														}
														name={`apps-${id}_work-sequence-execution`}
														type="text"
														placeholder="Последовательность выполнения"
													/>
												</div>
											)}
										</div>
									</div>
									<hr />
								</>
							)
						}
					</>
				)
			}

			{
				//Есть ли информационные материалы?
				((input[`workSequence${id}`] != null &&
					input[`workSequence${id}`] != "") ||
					(input.contractSignerId === "cs2" &&
						input.typeWorksId === "tw2" &&
						input.hasTask === "Нет") ||
					(input.contractSignerId === "cs2" &&
						input.typeWorksId === "tw2" &&
						input[`taskForm${id}`] != "" &&
						input[`taskForm${id}`] != null)) && (
					<>
						<div className="content-form-item content-form-item--full-width">
							<div className="content-form-item-desc">
								<label htmlFor="">
									{applications["materials-exists"].title}{" "}
									{applications["materials-exists"]
										.required && <i>*</i>}
								</label>
								<div
									dangerouslySetInnerHTML={{
										__html: applications["materials-exists"]
											.hint,
									}}></div>
							</div>

							<div className="whats-do-wrap">
								<div
									className="labels"
									data-required={
										applications["materials-exists"]
											.required
											? true
											: false
									}>
									{showLabelRadio(
										applications["materials-exists"],
										`apps-${id}_materials-exists`,
										`materialsExists${id}`
									)}
								</div>

								{(input[`materialsExists${id}Id`] === "me1" ||
									input[`materialsExists${id}Id`] ===
										"me2") && (
									<div
										className="adds"
										data-required={
											applications["materials-list"]
												.required
												? true
												: false
										}>
										<b>
											{
												applications["materials-list"]
													.title
											}
										</b>
										<span>
											Выберите один или несколько
											вариантов
										</span>
										<div className="checkboxs">
											{showLabelCheckbox(
												applications["materials-list"],
												`apps-${id}_materials-list[]`,
												`materialsList${id}`
											)}
										</div>

										{
											//Наименования, формат и объем/количество материалов
											input[`materialsList${id}`] !=
												null &&
												input[
													`materialsList${id}`
												].includes("ml12") && (
													<div className="textarea-block">
														<textarea
															onChange={(e) =>
																applicationInput(
																	`materialsListTotal${id}`,
																	e.target
																		.value
																)
															}
															name={`apps-${id}_materials-list-total`}
															placeholder="Наименования, формат и объем/количество материалов"></textarea>
													</div>
												)
										}
									</div>
								)}
							</div>
						</div>
						<hr />
					</>
				)
			}

			{
				//Схема оплаты
				input[`materialsExists${id}`] != null &&
					input[`materialsExists${id}`] != "" && (
						<>
							<div className="content-form-item content-form-item--full-width">
								<div className="content-form-item-desc">
									<label htmlFor="">
										{applications["payment-scheme"].title}{" "}
										{applications["payment-scheme"]
											.required && <i>*</i>}
									</label>
									<p>
										Авансовые платежи подрядчикам в
										обязательном порядке согласовываются с
										продакшн-директором
									</p>
								</div>

								<div
									className="label-radio-btns-wrap wrap-labels"
									data-required={
										applications["payment-scheme"].required
											? true
											: false
									}>
									{showLabelRadioBtn(
										applications["payment-scheme"],
										`apps-${id}_payment-scheme`,
										`paymentScheme${id}`
									)}
								</div>
								{(input[`paymentScheme${id}`] === "Другое" ||
									input[`paymentScheme${id}Id`] ===
										"ps7") && (
									<div className="whats-do-wrap">
										<div className="add-input">
											<br />
											<p>
												{
													applications[
														"payment-scheme-other"
													].hint
												}
											</p>
											<input
												onChange={() =>
													applicationInput(
														`paymentSchemeOther${id}`,
														"Другое"
													)
												}
												name={`apps-${id}_payment-scheme-other`}
												type="text"
												placeholder="Другое"
											/>
										</div>
									</div>
								)}
							</div>
							<hr />
						</>
					)
			}

			{
				//Дополнительные договоренности / комментарии
				input[`paymentScheme${id}`] != null &&
					input[`paymentScheme${id}`] != "" && (
						<>
							<div
								className="content-form-item content-form-item--full-width"
								style={{ marginBottom: "32px" }}>
								<div className="content-form-item-desc">
									<label htmlFor="">
										{
											applications["additional-comments"]
												.title
										}{" "}
										{applications["additional-comments"]
											.required && <i>*</i>}
									</label>
								</div>

								<div
									className="textarea-block"
									data-required={
										applications["additional-comments"]
											.required
											? true
											: false
									}>
									<textarea
										name={`apps-${id}_additional-comments`}
										placeholder="Введите сообщение…"
										onChange={(e) =>
											applicationInput(
												`additionalComments${id}`,
												e.target.value
											)
										}></textarea>
								</div>
							</div>
						</>
					)
			}
		</>
	);
};

export default Application;
