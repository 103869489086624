import React, {useState} from 'react'
import useStickyState from "../../assets/js/useStickyState";
import arrow from '../../assets/img/arr-bottom.svg'
import arrowBlack from '../../assets/img/arr-bottom-black.svg'

const RadioBtnsBlock = ({items, chgTypeWorks, nameForInput}) => {
    // const [isShowBlock, setIsShowBlock] = useStickyState(true, 'block-show-status');
    const [isShowBlock, setIsShowBlock] = useState(false);
    const toggleBlock = () => setIsShowBlock(!isShowBlock);

    let title = items.title;
    // nameForInput = items.nameForInput;

  return (
    <div className="radio-btns__item">
        <div className="title" onClick={toggleBlock}>
            <span>{title}</span>
            <i className={`${isShowBlock ? '' : '_rotate'}`}>
              <img src={arrow} alt="" />
              <img src={arrowBlack} alt="" />
            </i>
        </div>

        <div className={`radio-btns__item-box ${isShowBlock ? '' : '_hidden'}`}>
          {items.items.map((item, index) => {
              let itemTitle = item.title || item.name;
              return <label className='label-radio' key={itemTitle}>
                    <b>{itemTitle}</b>
                    <input onChange={chgTypeWorks} type="radio"  name={nameForInput ? nameForInput : title} value={item.id}
                            data-title={itemTitle}/>
                    <span></span>
              </label>
            }
          )}
        </div>
    </div>
  )
}

export default RadioBtnsBlock