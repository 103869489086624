import { React, useState, useEffect } from 'react'
import ReactSelect from 'react-select';
import Header from '../../components/Header/Header';
import styles from './EditNameUser.scss';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import spinner from '../../assets/img/spinner.svg';
import { useNavigate } from 'react-router-dom';

const EditNameUser = ({ changeTheme, themeBlack }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [usersArr, setUsersArr] = useState([]);
    const [usersArrChecked, setUsersArrChecked] = useState([]);
    const [errMessage, setErrMessage] = useState(false);
    const [countEntry, setCountEntry] = useState(null);
    const [udpatedData, setUdpatedData] = useState('');

    useEffect(() => {
        const getUsers = async () => {
            try {
                const { data } = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_BACKEND_URL}/v1/time-records-update/users`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                });

                if (data) {
                    setIsLoading(false);
                    console.log('Data ', data);
                    setUsersArr(data);
                }

            } catch (error) {
                setIsLoading(false);
                console.log('error');
            }
        }

        if (token) {
            getUsers()
        } else {
            navigate('/auth');
        }

    }, [])

    const updateNames = async (e) => {
        e.preventDefault();
        let arrayUsersData = [];
        let objUsersData = {
            user_ids: arrayUsersData
        };

        let usersChecked = document.querySelectorAll('input[name="user_ids[]"]:checked');

        usersChecked.forEach((itm) => {
            arrayUsersData.push(+itm.value);
        })
        if (objUsersData.user_ids.length !== 0) {
            setIsLoading(true);
            try {
                const { data } = await axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_BACKEND_URL}/v1/time-records-update/update`,
                    data: objUsersData,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                });

                if (data) {
                    setIsLoading(false);
                    setUdpatedData(data);
                    console.log('Data ', data);
                    setCountEntry(data.updated.length);
                }

            } catch (error) {
                setIsLoading(false);
                console.log('error');
            }
        } else {
            setErrMessage(true);
        }
    }

    const changeUsers = (e) => {
        let usersArrCheckedLocal = usersArrChecked;

        if (e.target.checked) {
            usersArrCheckedLocal.push(+e.target.value);
            setErrMessage(false);
        } else {
            let idx = usersArrCheckedLocal.indexOf(+e.target.value);
            usersArrCheckedLocal.splice(idx, 1);

            if (usersArrCheckedLocal.length === 0) {
                setErrMessage(true);
            }
        }
        setUsersArrChecked(usersArrCheckedLocal);
    }

    return (
        <div>
            <Helmet>
                <title>Правка имени пользователя</title>
            </Helmet>
            <div className="content content-edit-name">
                {isLoading &&
                    <div className="layout-fixed">
                        <h2>Выполняется запрос...</h2>
                        <img className="loading" src={spinner} alt="Spinner" />
                    </div>
                }
                <div className="container">
                    <h1>Правка имени пользователя</h1>

                    <form onSubmit={updateNames} className="users-wrap users-form">
                        {
                            udpatedData &&
                            <h3 className="message-updated">Записей обновлено: {countEntry}</h3>
                        }
                        <div className="title">Пользователи
                            {errMessage ? <span className='error-text'>Выберите хотя бы одного пользователя</span> : ''}
                        </div>
                        <div className="users-box list-scroll-wrap">
                            <div className="list-scroll-items">
                                {
                                    usersArr.map((item) => {
                                        return <label key={item.id} className="label-radio-btns">
                                            <input type="checkbox" onChange={(e) => changeUsers(e)} name="user_ids[]" value={item.id} />
                                            <span>{item.first_name + ' ' + item.last_name}</span>
                                        </label>
                                    })
                                }
                            </div>
                        </div>
                        <button type="submit" className="btn-white">Запустить</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditNameUser