import { v4 as uuidv4 } from 'uuid'
import InputMask from "react-input-mask"

function Input(props) {
    if (props.mask) {
        return <InputMask {...props} />
    }
    return <input {...props} />
}

function ControlInput(props) {
    const {
        className,
        id,
        name,
        type = 'text',
        onChange,
        ...rest
    } = props
    const htmlFor = 'for_' + id

    return (
        <Input
            className={className}
            id={htmlFor}
            name={name}
            type={type}
            onChange={e => onChange({
                name,
                type,
                event: e,
                value: {
                    value: e.target.value,
                    id
                }
            })}
            {...rest}
        />
    )
}

export default ControlInput