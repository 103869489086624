import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import styles from './NonDevPayments.scss';

const NonDevPayments = () => {

    const [toggleState, setToggleState] = useState(1);

    let masterUrl = "https://docs.google.com/spreadsheets/d/11SL4M5fYxJI54NFSKML51HinnTKHWpSqDUF1Yh3Omok";
    let axoUrl = "https://docs.google.com/spreadsheets/d/1WjyKZWAWJXLW_zJD4Hs0Se9EBQtWAfX9_SLiYR8Lvrk";
    let itUrl = "https://docs.google.com/spreadsheets/d/1wLOOSzUy7WwPyxaD2uV7sulacKpACABvWq60qnoJCKY";
    let admUrl = "https://docs.google.com/spreadsheets/d/1erN-CxMdZgV5bXszc3qxM3t9QbbBERNtWT-CicFlVv8";
    let hrUrl = "https://docs.google.com/spreadsheets/d/111GCQIU6Rc--uZVw_TMyXrg6GjBJDOJ0-yZZxoywLD4";
    let prUrl = "https://docs.google.com/spreadsheets/d/1q-y0-ay21yzLx8mKIwi5lBZNpLJ_VLkX6Mlpgi31Mdo";
    let salesUrl = "https://docs.google.com/spreadsheets/d/1P9heXTd9_DGVz68AiLlRcJAzvKiigdZqln4tDmFVTxc";

    const toggleTab = (e, i) => {
        e.preventDefault();
        setToggleState(i);
    }

    const getActiveClass = (i, className) =>
        toggleState === i ? className : "";

    return (
        <div>
            <Helmet>
                <title>Непроизводственные платежи</title>
            </Helmet>

            <div className="content content-nondevpayments">
                <div className="container">
                    <div className="nondev-tabs">
                        <a href="#" className={`nondev-tab ${getActiveClass(1, "_active")}`} onClick={(e) => toggleTab(e, 1)}>Master</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(2, "_active")}`} onClick={(e) => toggleTab(e, 2)}>АХО</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(3, "_active")}`} onClick={(e) => toggleTab(e, 3)}>IT</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(4, "_active")}`} onClick={(e) => toggleTab(e, 4)}>ADM</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(5, "_active")}`} onClick={(e) => toggleTab(e, 5)}>HR</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(6, "_active")}`} onClick={(e) => toggleTab(e, 6)}>PR</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(7, "_active")}`} onClick={(e) => toggleTab(e, 7)}>Sales</a>
                    </div>
                </div>
            </div>

            <div className="frame-box">
                <div className={`frame-box__item ${getActiveClass(1, "_active")}`}>
                    <iframe src={masterUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(2, "_active")}`}>
                    <iframe src={axoUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(3, "_active")}`}>
                    <iframe src={itUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(4, "_active")}`}>
                    <iframe src={admUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(5, "_active")}`}>
                    <iframe src={hrUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(6, "_active")}`}>
                    <iframe src={prUrl} frameBorder="0"></iframe>7
                </div>

                <div className={`frame-box__item ${getActiveClass(7, "_active")}`}>
                    <iframe src={salesUrl} frameBorder="0"></iframe>
                </div>
            </div>
        </div>
    )
}

export default NonDevPayments