import React from 'react'
import useStickyState from "../../assets/js/useStickyState";
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import styles from './Payments.scss';

const Payments = ({ changeTheme, themeBlack }) => {
  return (
    <div>
      <Helmet>
        <title>Внешние платежи</title>
      </Helmet>

      <div className="frame-box">
        <iframe src="https://docs.google.com/spreadsheets/d/15atgo-PMhu-ZD0NwWP-S9vMPSNjtpA43seaY2Bcbj2Q/edit" frameBorder="0"></iframe>
      </div>
    </div>
  )
}

export default Payments