import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';
import useStickyState from '../assets/js/useStickyState';
import logo from "../assets/img/logo.svg";
import logoBlack from "../assets/img/logo-black.svg";
import arrUp from "../assets/img/arr-up.svg";
import arrUpBlack from "../assets/img/arr-up-black.svg";
import arrBlack from "../assets/img/arr-black.svg";
import Nav from './Nav/Nav';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import HeaderSmall from './HeaderSmall/HeaderSmall';
import axios from 'axios';

const Layout = (props) => {
    let location = useLocation();
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("token"));
    const [userInfo, setUserInfo] = useState({});
    const [permissionsList, setPermissionsList] = useState([]);
    const [rolesList, setRolesList] = useState([]);

    // белый фон для некоторых страниц
    let whiteBg = location.pathname === '/payments' || location.pathname === '/acts' || location.pathname === '/receipts' || location.pathname === '/404' || location.pathname === '/no-access' ? true : false;

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const { data } = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_BACKEND_URL}/v1/user`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                });

                if (data) {
                    setUserInfo(data);
                    let arrPermissions = [];
                    let arrRoles = [];

                    data.permissions.map((item) => {
                        arrPermissions.push(item.name)
                    })

                    data.roles.map((item) => {
                        arrRoles.push(item.name)
                    })

                    setPermissionsList(arrPermissions);
                    setRolesList(arrRoles);

                    // console.log(arrPermissions);
                    // console.log(arrRoles);
                    // console.log(location);

                    // console.log('Пользователь авторизован');
                    // console.log('Данные пользователя ', data);

                    localStorage.setItem('arrRoles', JSON.stringify(arrRoles));
                    localStorage.setItem('arrPermissions', JSON.stringify(arrPermissions));
                }
            } catch (error) {
                console.log('Срок действия токена истёк или токен неправильный');
                navigate('/auth');
            }
        }

        if (token) {
            fetchToken()
        } else {
            navigate('/auth');
        }
    }, [])

    const [themeBlack, setThemeBlack] = useStickyState(true, true)
    const [isBlackChecked, setIsBlackChecked] = useStickyState(true, 'ThemeBlackChecked')

    const changeTheme = (evt) => {
        setThemeBlack(!themeBlack);
        setIsBlackChecked(!isBlackChecked);
    }

    return (
        <div className={`App ${!themeBlack ? '_white-theme' : ''} ${whiteBg ? '_white-bg' : ''}`}>
            {
                location.pathname === '/auth' ?
                    <HeaderSmall useStickyState={useStickyState} isBlackChecked={isBlackChecked} themeBlack={themeBlack} changeTheme={changeTheme} /> :
                    <Header userInfo={userInfo} permissionsList={permissionsList} rolesList={rolesList} useStickyState={useStickyState}
                        isBlackChecked={isBlackChecked} themeBlack={themeBlack} changeTheme={changeTheme} />
            }
            <Outlet />
            <Footer />
        </div>
    )
}

export default Layout;
