import React, { useEffect } from 'react'
import ControlCheckbox from '../../components/Signature/ControlCheckbox'
import styles from './Auth.scss';
import { Helmet } from 'react-helmet';
import useStickyState from '../../assets/js/useStickyState';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import spinner from '../../assets/img/spinner.svg';
import axios from 'axios';
import authImg1 from '../../assets/img/auth-1.svg'
import authImg2 from '../../assets/img/auth-2.svg'
import authImg1Black from '../../assets/img/auth-1-black.svg'
import authImg2Black from '../../assets/img/auth-2-black.svg'

const Auth = () => {
    const navigate = useNavigate();
    const emailRef = React.useRef();
    const passwordRef = React.useRef();
    const rememberRef = React.useRef();

    const [succsessFormAuth, setSuccsessFormAuth] = useState(null);
    const [pendingReq, setPendingReq] = useState(false);
    const [token, setToken] = useStickyState(null, 'token');

    function handlerAuth(e) {
        e.preventDefault();
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const remember = rememberRef.current.value;
        const authData = JSON.stringify({ "email": email, "password": password });

        const fetchData = async () => {
            try {
                setPendingReq(true);
                const { data } = await axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_BACKEND_URL}/v1/login`,
                    data: authData,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                });

                if (data.token) {
                    setPendingReq(false);
                    console.log('Токен получен');
                    let startingURL = localStorage.getItem("startingURL") ? localStorage.getItem("startingURL") : null;
                    setToken(data.token);
                    setSuccsessFormAuth(true);
                    setTimeout(() => {
                        if (startingURL) {
                            navigate(startingURL);
                        } else {
                            navigate('/');
                        }
                    }, 500);

                    setTimeout(() => {
                        localStorage.removeItem("startingURL");
                    }, 1000);
                }
            } catch (error) {
                setPendingReq(false);
                console.log("Авторизоваться не удалось, попробуйте снова.");
                setSuccsessFormAuth(false);
            }
        }
        fetchData()
    }

    return (
        <div className='auth-wrapper'>
            <Helmet>
                <title>Авторизация</title>
            </Helmet>
            <div className="content content-auth">
                <div className="container">
                    <div className="auth-top">
                        <div className="auth-top__left">
                            <img src={authImg1} alt="" />
                            <img src={authImg1Black} alt="" />
                        </div>
                        <div className="auth-top__right">
                            <img src={authImg2} alt="" />
                            <img src={authImg2Black} alt="" />
                        </div>

                        <div className="text-center">
                            <h1>Привет!</h1>
                            <p>Это сайт для сотрудников CreativePeople.<br />
                                Для входа используйте ваш логин и пароль от ActiveCollab.</p>
                        </div>
                    </div>
                    <form onSubmit={handlerAuth} method="POST" className='form-auth'>
                        <input id="email" type="text" name="email" required placeholder='Логин в ActiveCollab' ref={emailRef} />
                        <input id="password" type="password" name="password" required placeholder='Пароль в ActiveCollab' ref={passwordRef} />
                        <div className="form-auth-bottom">
                            <button className='btn-white' type='submit'>Войти</button>
                            <label className='label-checkbox'>
                                <b>Запомнить меня</b>
                                <input type="checkbox" name="remember" value="yes" ref={rememberRef} />
                                <span></span>
                            </label>
                        </div>
                    </form>

                    {
                        !succsessFormAuth && succsessFormAuth != null &&
                        <div className='form-auth-message text-center'>
                            <h2>Авторизация не удалась, попробуйте снова.</h2>
                        </div>
                    }

                    {
                        !pendingReq ? '' : <img className="loading" src={spinner} alt="Spinner" />
                    }
                </div>
            </div>
        </div>
    )
}

export default Auth