import React, { useEffect } from 'react'
import axios from "axios"
import { useNavigate } from 'react-router-dom'

function Logout(props) {
    const token = JSON.parse(localStorage.getItem("token"));
    const navigate = useNavigate();

    const logout = async () => {
        try {
            const { data } = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_BACKEND_URL}/v1/logout`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            });
            if (data) {
                console.log("data", data);

                if (data.OK) {
                    localStorage.removeItem('token');
                    setTimeout(() => {
                        navigate('/auth');
                    }, 500);
                } else {
                    console.log("Не получилось выйти")
                }
            } else {
                console.log("Данные не получены")
            }
        } catch (error) {
            console.log("fetching error")
        }
    }


    return (
        <div onClick={logout} className="link-auth">Выход</div>
    )
}

export default Logout