import { React, useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { addDays, subDays } from "date-fns";
import { ru } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Datepicker.scss";

export function convertDate(str) {
	var date = new Date(str),
		mnth = ("0" + (date.getMonth() + 1)).slice(-2),
		day = ("0" + date.getDate()).slice(-2);
	return [day, mnth, date.getFullYear()].join(".");
}

const Datepicker = (props) => {
	const [startDate, setStartDate] = useState(null);
	const lastDateOfYear = "12.31.2030";

	const dateInputF = function (date) {
		let inputName = props.inputName || "date";
		setStartDate(date);
		if (props.chgDate) {
			props.chgDate(date, props.name, inputName);
		}
	};

	registerLocale("ru", ru);
	return (
		<div className="date-block">
			<DatePicker
				showMonthDropdown
				showYearDropdown
				dropdownMode="select"
				name={props.name}
				className={props.className}
				dateFormat="dd.MM.yyyy"
				placeholderText={
					props.name === "date-retro-clause"
						? "Укажите реальную дату начала работ"
						: "Выберите дату"
				}
				locale={ru}
				selected={startDate}
				onChange={dateInputF}
				minDate={
					props.minDate
						? typeof props.minDate == "boolean"
							? subDays(new Date(), 0)
							: subDays(new Date(props.minDate), 0)
						: false
				}
				maxDate={
					props.maxDate
						? typeof props.maxDate == "boolean"
							? addDays(new Date(), 0)
							: addDays(new Date(props.maxDate), 0)
						: new Date(lastDateOfYear)
				}
			/>
		</div>
	);
};

export default Datepicker;
