import useStickyState from "../../assets/js/useStickyState";
import { React, useState, useEffect } from "react";
import ReactSelect from "react-select";
import calendar from "../../assets/img/calendar.svg";
import styles from "./ExternalPayment.scss";
import axios from "axios";
import Header from "../../components/Header/Header";
import Datepicker, {
	convertDate,
} from "../../components/Datepicker/Datepicker";
import { Helmet } from "react-helmet";
import DatalistInput from "react-datalist-input";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import LabelRadio from "../../components/LabelRadio/LabelRadio";
import LabelRadioBtn from "../../components/LabelRadioBtn/LabelRadioBtn";
import spinner from "../../assets/img/spinner.svg";
import { set } from "date-fns";

const cookies = new Cookies();

let changeTimer = false;
const timeout = 150;

const companiesList = [];
const specialisList = [];
const clients = [];
const clientContract = [];
const clientContractWithoutDoubles = [];
const clientAppendixWithoutDoubles = [];
const clientApp = [];
const internalProject = [];
const taskList = [];

const unDuplicateArraySingleValues = (array) => {
	if ((Array.isArray(array) || array instanceof Array) && array.length) {
		return [...new Set(array)];
	} else {
		return array;
	}
};

// сортировка по алфавиту
const sortABC = (arr) => {
	arr.sort(function (a, b) {
		let textA = a.value.toUpperCase();
		let textB = b.value.toUpperCase();
		return textA < textB ? -1 : textA > textB ? 1 : 0;
	});
};

// удаление дубликатов из массива
const removeDuplicates = (oldArr, newArr) => {
	let uniqueObject = {};
	for (let i in oldArr) {
		let objTitle = oldArr[i]["value"];
		uniqueObject[objTitle] = oldArr[i];
	}

	for (let i in uniqueObject) {
		newArr.push(uniqueObject[i]);
	}
};

removeDuplicates();

const ExternalPayment = ({ changeTheme, themeBlack }) => {
	const token = JSON.parse(localStorage.getItem("token"));
	const navigate = useNavigate();

	let productsList = [];
	let stagesList = {};

	const [conntChenageClient, setConntChenageClient] = useState(0);

	const [isLoading, setIsLoading] = useState(false);
	const [typePayment, setTypePayment] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [typeWorkContractor, setTypeWorkContractor] = useState([]);
	const [typeContractor, setTypeContractor] = useState([]);
	const [typeMoney, setTypeMoney] = useState([]);
	const [products, setProducts] = useState([]);
	const [clientId, setClientId] = useState("");
	const [errorProdAndStage, setErrorProdAndStage] = useState(false);
	const [internalProjectId, setInternalProjectId] = useState("");
	const [valContract, setValContract] = useState("");
	const [valAppendix, setValAppendix] = useState("");
	const [valBudgetClientApp, setValBudgetClientApp] = useState("");
	const [valBudgetClientTask, setValBudgetClientTask] = useState("");
	const [valAmount, setValAmount] = useState("");
	const [submitForm, setSubmitForm] = useState(false);
	const [formHide, setFormHide] = useState(false);
	const [readyProductsAndStages, setReadyProductsAndStages] = useState(false);
	const [valIntProject, setValIntProject] = useState("");
	const [valProduct, setValProduct] = useState("");
	const [valStage, setValStage] = useState("");
	const [valTaskList, setValTaskList] = useState("");
	const [errorsList, setErrorsList] = useState({});
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [disabledBtn, setDisabledBtn] = useState(false);
	const [linkTable, setLinkTable] = useState("");
	const [numContractNullList, setNumContractNullList] = useState("");
	const [numAppNullList, setNumAppNullList] = useState("");
	const [loadContractNums, setLoadContractNums] = useState(false);

	const [contractInProccess, setContractInProccess] = useState(false);
	const [tender, setTender] = useState(false);

	// получаем поля для первого шага
	useEffect(() => {
		const fetchData = async () => {
			try {
				const { data } = await axios({
					method: "GET",
					url: `${process.env.REACT_APP_BACKEND_URL}/v1/types/payment`,
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: "Bearer " + token,
					},
				});

				if (data) {
					setTypePayment(data.data);
				}
			} catch (error) {
				console.log("error");
			}
		};

		if (token) {
			fetchData();
		} else {
			navigate("/auth");
		}
	}, []);

	const [isProject, setIsProject] = useState(false);
	const [numReq, setNumReq] = useState("");

	// объект который заполнеям по мере заполнения формы
	const [formValue, setformValue] = useState({
		payment_type_id: "",
		department_id: "",
		client_name: "",
		client_budget_application: "",
		client_budget_task: "",
		contract_number: "",
		app_number: null,
		collab_project_name: "",
		collab_project_task_list: "",
		product: "",
		stage: "",
		payment_date: "",
		assignment_date: "",
		contractor_work_type_id: "",
		contractor_type_id: "",
		contractor_name: "",
		contractor_work: "",
		contractor_contract_number: null,
		contractor_contract_annex_number: null,
		payment_number: null,
		payment_count: null,
		money_type_id: "",
		invoice_scan_link: "",
		amount: "",
		pay_up_date: "",
		comments: "",
	});

	const getInternalProjects = (id) => {
		setIsLoading(true);
		axios({
			method: "GET",
			url: `${process.env.REACT_APP_BACKEND_URL}/v1/collab/projects?filters[clients_ids][]=${id}&filters[labels_ids][]=105&filters[labels_ids][]=369&filters[is_completed]=0&filters[is_archived]=0`,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then(function (response) {
				setIsLoading(false);

				if (taskList.length) {
					taskList.splice(0, taskList.length);
				}

				if (internalProject.length) {
					internalProject.splice(0, internalProject.length);
				}

				response.data.data.map((itm, idx) => {
					internalProject.push({
						value: itm.name,
						label: itm.name,
						id: itm.id,
					});
				});
			})
			.catch(function (response) {
				setIsLoading(false);
				console.log("error");
			});
	};

	const getTaskLists = (id) => {
		setIsLoading(true);
		axios({
			method: "GET",
			url: `${process.env.REACT_APP_BACKEND_URL}/v1/collab/projects/${id}/task-lists`,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then(function (response) {
				setIsLoading(false);

				if (taskList.length) {
					taskList.splice(0, taskList.length);
				}
				response.data.data.map((itm, idx) => {
					taskList.push({
						value: itm.name,
						label: itm.name,
						id: itm.id,
					});
				});
			})
			.catch(function (response) {
				setIsLoading(false);
				console.log("error");
			});
	};

	const checkErrors = () => {
		setTimeout(() => {
			if (Object.values(errorsList).includes(true)) {
				setError(true);
				setErrorMessage(true);
			} else {
				setError(false);
				setErrorMessage(false);
			}
		}, 350);
	};

	function validateForm(form) {
		const formFields = form.querySelectorAll(`input, textarea, select`);
		const elementError = document.createElement("div");
		elementError.innerHTML = "Не заполнено поле";
		elementError.classList.add("ajax-error");

		formFields.forEach((item) => {
			let itemName = item.getAttribute("name");

			if (item.closest('[data-required="true"]')) {
				item.closest(".content-form-item")
					.querySelectorAll(".ajax-error")
					.forEach((e) => e.remove());
				item.closest(".content-form-item").classList.remove(
					"error-field"
				);

				if (
					item.getAttribute("type") === "radio" ||
					item.getAttribute("type") === "checkbox"
				) {
					if (!form.querySelector(`[name="${itemName}"]:checked`)) {
						item.closest(".content-form-item").prepend(
							elementError
						);
					}
				} else {
					if (item.value === "" || item.value.length === 0) {
						item.closest(".content-form-item").prepend(
							elementError
						);
						item.closest(".content-form-item").classList.add(
							"error-field"
						);
					}
				}
			}
		});

		const errorMessages = form.querySelectorAll(`.ajax-error`);
		if (errorMessages.length > 0) {
			form.querySelectorAll(".ajax-error")[0].scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
			return false;
		}

		return true;
	}

	// разрешаем ввод только цифр и запятой, отделяем разряды
	const addCommas = (num) =>
		num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	const removeNonNumeric = (num) => num.toString().replace(/[^\d,]/g, "");
	// проверяем чтобы не было больше одной запятой
	const checkInputDot = (e) => {
		let i = 0;
		e.target.value = e.target.value.replace(/\D/g, (e) => {
			return e === "," ? (++i > 1 ? "" : ",") : "";
		});
	};

	// функция при изменении полей в каждом их шагов
	const handleChange = (event, url, nameEl, inputName, required) => {
		if (nameEl === "client_budget_application") {
			checkInputDot(event);
			setValBudgetClientApp(
				addCommas(removeNonNumeric(event.target.value))
			);
		}

		if (nameEl === "client_budget_task") {
			checkInputDot(event);
			setValBudgetClientTask(
				addCommas(removeNonNumeric(event.target.value))
			);
		}

		if (nameEl === "product") {
			setValProduct(event.value);
		}

		if (nameEl === "amount") {
			checkInputDot(event);
			setValAmount(addCommas(removeNonNumeric(event.target.value)));
		}

		// устанавливаем ID выбранного клиента
		if (nameEl === "client_name") {
			setClientId(event.id);

			setConntChenageClient(conntChenageClient + 1);

			// если решили поменять клиента, тогда обнуляем зависящие от него поля
			if (conntChenageClient > 0) {
				setValIntProject("");
				setValTaskList("");
				setValContract("");
				setValAppendix("");
				setValBudgetClientApp("");
				setValBudgetClientTask("");

				let updatedValue = {};
				updatedValue = {
					client_budget_application: "",
					client_budget_task: "",
					collab_project_name: "",
					collab_project_task_list: "",
					contract_number: "",
					app_number: "",
				};

				setTimeout(() => {
					setformValue((formValue) => ({
						...formValue,
						...updatedValue,
					}));
					setErrorProdAndStage(false);
				}, 350);
			}

			setTimeout(() => {
				getInternalProjects(event.id);
			}, 150);
		}

		// делаем запрос на список задач при выборе внутреннего проекта
		if (nameEl === "collab_project_name") {
			setInternalProjectId(event.id);
			setValIntProject(event.value);
			setValProduct("");
			setValStage("");
			setTimeout(() => {
				getTaskLists(event.id);
			}, 150);
		}

		// обнуляем поле в объекта при сбросе селекта
		if (nameEl === "collab_project_task_list") {
			if (event === null) {
				setformValue({
					...formValue,
					collab_project_task_list: null,
				});
			} else {
				setValTaskList(event.value);
			}
		}

		if (url.includes("[projects_ids]")) {
			setErrorProdAndStage(false);
			setReadyProductsAndStages(false);
			setIsLoading(true);
		}

		// на этапе выбора Типа подрядчика проверям что выбрали, и в следующее поле в зависимости от выбора подгружаем либо Компании либо Специалистов
		if (nameEl === "contractor_type_id") {
			if (+event.target.value === 1) {
				url = `${process.env.REACT_APP_BACKEND_URL}/v1/collab/companies`;
			} else {
				url = `${process.env.REACT_APP_BACKEND_URL}/v1/collab/users`;
			}
		}

		if (changeTimer !== false) {
			clearTimeout(changeTimer);
		}

		// если передали url
		if (url !== "") {
			changeTimer = setTimeout(() => {
				setIsLoading(true);
				if (nameEl !== "") {
					if (event.target) {
						if (required === true) {
							if (
								event.target.value === null ||
								event.target.value === ""
							) {
								setErrorsList({
									...errorsList,
									[nameEl]: true,
								});
							} else {
								setErrorsList({
									...errorsList,
									[nameEl]: false,
								});
							}
						}

						setformValue({
							...formValue,
							[nameEl]: event.target.value,
						});
					} else if (event.value) {
						setformValue({
							...formValue,
							[nameEl]: event.value,
						});
					} else if (
						(nameEl === "pay_up_date" ||
							nameEl === "payment_date" ||
							nameEl === "assignment_date") &&
						event
					) {
						if (event === null) {
							setErrorsList({ ...errorsList, [nameEl]: true });
						} else {
							setErrorsList({ ...errorsList, [nameEl]: false });
						}
						setformValue({
							...formValue,
							[nameEl]: convertDate(event),
						});
					} else if (event) {
						setformValue({
							...formValue,
							[nameEl]: event,
						});
					}
				}
				axios({
					method: "GET",
					url: url,
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: "Bearer " + token,
					},
				})
					.then(function (response) {
						setIsLoading(false);
						setErrorProdAndStage(false);
						if (typeof inputName !== "string") {
							if (!url.includes("products-and-stages")) {
								inputName(response.data.data);
							} else {
								if (response.data.length === 0) {
									errorProdAndStage(true);
								} else {
									inputName(response.data);
									setReadyProductsAndStages(true);
								}
							}
						}

						if (url.includes("companies")) {
							response.data.data.map((itm, idx) => {
								companiesList.push({
									value: itm.name,
									label: itm.name,
								});
							});
							sortABC(companiesList);
						}
						if (url.includes("users")) {
							response.data.data.map((itm, idx) => {
								if (
									itm.first_name !== null &&
									itm.first_name !== "" &&
									itm.last_name !== null &&
									itm.last_name !== ""
								) {
									specialisList.push({
										value:
											itm.first_name +
											" " +
											itm.last_name,
										label:
											itm.first_name +
											" " +
											itm.last_name,
									});
								}

								sortABC(specialisList);
							});
						}
						if (url.includes("/clients")) {
							if (clients.length) {
								clients.splice(0, clients.length);
							}
							response.data.data.map((itm, idx) => {
								clients.push({
									value: itm.name,
									label: itm.name,
									id: itm.id,
								});
							});

							sortABC(clients);
						}
						if (url.includes("contracts")) {
							setLoadContractNums(true);
							clientContractWithoutDoubles.splice(
								0,
								clientContractWithoutDoubles.length
							);
							clientAppendixWithoutDoubles.splice(
								0,
								clientAppendixWithoutDoubles.length
							);
							response.data.map((itm, idx) => {
								let appendix = itm.appendix
									? " - " + itm.appendix
									: "";
								clientContract.push({
									id: idx,
									value: itm.contract,
								});
							});

							removeDuplicates(
								clientContract,
								clientContractWithoutDoubles
							);

							response.data.map((itm, idx) => {
								clientApp.push({
									id: idx,
									value: itm.appendix,
								});
							});

							removeDuplicates(
								clientApp,
								clientAppendixWithoutDoubles
							);
						}
						if (url.includes("[clients_ids]")) {
							// для того чтобы поля "Номер клиентского договора" показывались после ответа от сервера
							setLoadContractNums(false);
							if (internalProject.length) {
								internalProject.splice(
									0,
									internalProject.length
								);
							}
							response.data.data.map((itm, idx) => {
								internalProject.push({
									value: itm.name,
									label: itm.name,
									id: itm.id,
								});
							});
						}
					})
					.catch(function (response) {
						setIsLoading(false);
						console.log("error");
						if (url.includes("[projects_ids]")) {
							setErrorProdAndStage(true);
						}
					});

				changeTimer = false;
			}, timeout);
		}

		// если НЕ передали url
		else {
			if (nameEl !== "") {
				if (event === null) {
					setValTaskList(null);

					if (
						nameEl === "pay_up_date" ||
						nameEl === "payment_date" ||
						nameEl === "assignment_date"
					) {
						setErrorsList({ ...errorsList, [nameEl]: true });
						setformValue({
							...formValue,
							[nameEl]: null,
						});
					}
				} else if (event.value) {
					setformValue({
						...formValue,
						[nameEl]: event.value,
					});
					setErrorsList({ ...errorsList, [nameEl]: false });
				} else if (event.target) {
					if (required === true) {
						if (
							event.target.value === null ||
							event.target.value === ""
						) {
							setErrorsList({ ...errorsList, [nameEl]: true });
						} else {
							setErrorsList({ ...errorsList, [nameEl]: false });
						}
					}

					setformValue({
						...formValue,
						[nameEl]: event.target.value,
					});
				} else if (
					(nameEl === "pay_up_date" ||
						nameEl === "payment_date" ||
						nameEl === "assignment_date") &&
					event
				) {
					setErrorsList({ ...errorsList, [nameEl]: false });
					setformValue({
						...formValue,
						[nameEl]: convertDate(event),
					});
				} else if (event) {
					setformValue({
						...formValue,
						[nameEl]: event,
					});
				}
			}
		}
	};

	// отправка формы
	function handlerExPayment(e) {
		e.preventDefault();
		const form = e.target;

		if (!validateForm(form)) {
			return false;
		}

		const FormDataExPayment = {};

		//сбор данных из объекта
		Object.assign(FormDataExPayment, {
			payment_type_id: +formValue.payment_type_id,
		});
		Object.assign(FormDataExPayment, {
			department_id: +formValue.department_id,
		});
		if (formValue.payment_type_id === "2") {
			Object.assign(FormDataExPayment, {
				client_name: formValue.client_name,
			});
			Object.assign(FormDataExPayment, {
				client_budget_application: parseFloat(
					formValue.client_budget_application
						.replace(",", ".")
						.replace(/ /g, "")
				),
			});
			Object.assign(FormDataExPayment, {
				client_budget_task: parseFloat(
					formValue.client_budget_task
						.replace(",", ".")
						.replace(/ /g, "")
				),
			});
			Object.assign(FormDataExPayment, {
				contract_number: formValue.contract_number,
			});
			Object.assign(FormDataExPayment, {
				app_number: formValue.app_number,
			});
			Object.assign(FormDataExPayment, {
				collab_project_name: formValue.collab_project_name,
			});
			Object.assign(FormDataExPayment, {
				collab_project_task_list: formValue.collab_project_task_list,
			});
			Object.assign(FormDataExPayment, { product: formValue.product });
			Object.assign(FormDataExPayment, { stage: formValue.stage });
			Object.assign(FormDataExPayment, {
				payment_date: formValue.payment_date,
			});
			Object.assign(FormDataExPayment, {
				assignment_date: formValue.assignment_date,
			});
		}
		Object.assign(FormDataExPayment, {
			contractor_work_type_id: +formValue.contractor_work_type_id,
		});
		Object.assign(FormDataExPayment, {
			contractor_type_id: +formValue.contractor_type_id,
		});
		Object.assign(FormDataExPayment, {
			contractor_name: formValue.contractor_name,
		});
		Object.assign(FormDataExPayment, {
			contractor_work: formValue.contractor_work,
		});
		Object.assign(FormDataExPayment, {
			contractor_contract_number: formValue.contractor_contract_number,
		});
		Object.assign(FormDataExPayment, {
			contractor_contract_annex_number:
				formValue.contractor_contract_annex_number,
		});
		Object.assign(FormDataExPayment, {
			payment_number:
				formValue.payment_number === "" ||
				formValue.payment_number === null
					? null
					: +formValue.payment_number,
		});
		Object.assign(FormDataExPayment, {
			payment_count:
				formValue.payment_count === "" ||
				formValue.payment_count === null
					? null
					: +formValue.payment_count,
		});
		Object.assign(FormDataExPayment, {
			money_type_id: +formValue.money_type_id,
		});
		Object.assign(FormDataExPayment, {
			amount: parseFloat(
				formValue.amount.replace(",", ".").replace(" ", "")
			),
		});
		Object.assign(FormDataExPayment, {
			invoice_scan_link: formValue.invoice_scan_link,
		});
		Object.assign(FormDataExPayment, {
			pay_up_date: formValue.pay_up_date,
		});
		Object.assign(FormDataExPayment, { comments: formValue.comments });

		setIsLoading(true);

		axios({
			method: "POST",
			url: `${process.env.REACT_APP_BACKEND_URL}/v1/documents/external-payments`,
			data: FormDataExPayment,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"X-Requested-With": "XMLHttpRequest",
				Authorization: "Bearer " + token,
			},
		})
			.then(function (response) {
				setIsLoading(false);
				setSubmitForm(true);
				setFormHide(true);
				setNumReq(response.data.request_id);
				setLinkTable(response.data.document_url);
			})
			.catch(function (response) {
				setIsLoading(false);
				setErrorsList(response.response.data.error);
			});
	}

	function setValContractFunc(e) {
		e.preventDefault();
		setValContract(e.target.text);

		if (e.target.text === "Тендер") {
			setTender(true);
			setContractInProccess(false);
		} else {
			setTender(false);
			setContractInProccess(true);
		}

		setformValue({
			...formValue,
			contract_number: e.target.text,
		});

		handleChange(
			e,
			`${process.env.REACT_APP_BACKEND_URL}/v1/collab/projects?filters[clients_ids][]=${clientId}&filters[labels_ids][]=105&filters[labels_ids][]=369&filters[is_completed]=0&filters[is_archived]=0`,
			"",
			"",
			true
		);
	}

	function chgDate(date, name, inputName) {
		setformValue((prev) => ({ ...prev, [inputName]: date }));
	}

	function setNameContractor(event) {
		setformValue({
			...formValue,
			nameContractor: event.target.value,
		});
	}

	// устанавливаем номер клиентского договора в объект
	function setNumClientContract(event) {
		setValContract(event.value);
		setformValue({
			...formValue,
			contract_number: event.value,
		});

		handleChange(event, "", "contract_number", "contract_number", true);
	}

	// устанавливаем номер клиентского договора в объект если не найдено договоров
	function setNumClientContractNullResponse(event) {
		setValContract(event.target.value);
		setformValue({
			...formValue,
			contract_number: event.target.value,
		});

		handleChange(event, "", "contract_number", "contract_number", true);
	}

	// устанавливаем номер клиентского приложения в объект если не найдено договоров
	function setNumClientAppNullResponse(event) {
		setValAppendix(event.target.value);
		setformValue({
			...formValue,
			app_number: event.target.value,
		});
	}

	// устанавливаем номер клиентского приложения в объект
	function setNumClientApp(event) {
		setValAppendix(event.value);
		setformValue({
			...formValue,
			app_number: event.value,
		});
	}

	// если в номере клиентского приложения очистили значение то app_number делаем null
	function changeNumClientApp(event) {
		if (event.target.value.length === 0) {
			setformValue({
				...formValue,
				app_number: null,
			});
		}
	}

	function repeatRequest(e, key) {
		e.preventDefault();
		setFormHide(false);
		setSubmitForm(false);

		for (const prop of Object.getOwnPropertyNames(formValue)) {
			delete formValue[prop];
		}
	}

	function repeatRequestProject(e, key) {
		e.preventDefault();
		if (cookies.get("objLastReqest")) {
			setformValue(cookies.get("objLastReqest"));
		}
		setFormHide(false);
		setSubmitForm(false);
	}

	// разбор радио-точек
	function showLabelRadio(obj, nameEl, inputName, url) {
		return obj.map((item, key) => {
			return (
				<LabelRadio
					key={key}
					changeLabelRadio={(e) =>
						handleChange(e, url, nameEl, inputName)
					}
					name={nameEl}
					value={item.id}
					title={item.name}
				/>
			);
		});
	}

	// разбор радио-кнопок
	function showLabelRadioBtn(obj, nameEl, inputName, url, req) {
		return obj.map((item, key) => {
			return (
				<LabelRadioBtn
					required={req ? true : false}
					key={key}
					changeLabelRadioBtn={(e) =>
						handleChange(e, url, nameEl, inputName)
					}
					name={nameEl}
					value={item.id}
					title={item.name}
				/>
			);
		});
	}

	// console.log("formValue", formValue);

	return (
		<div>
			<Helmet>
				<title>Заявка на внешний платеж</title>
			</Helmet>
			<div className="content content-external-payment">
				{isLoading && (
					<div className="layout-fixed">
						<img className="loading" src={spinner} alt="Spinner" />
					</div>
				)}

				<div className="container">
					<h1>
						Заявка <br /> на внешний платеж
					</h1>

					{formHide === false && (
						<>
							<div className="desc">
								Все поля помеченные <i>*</i> обязательны для
								заполнения
							</div>
							<form
								autoComplete="off"
								method="POST"
								onSubmit={handlerExPayment}>
								<div
									className="content-form-item content-form-item-row"
									data-required="true">
									<div className="content-form-item-desc">
										<label>
											Тип платежа <i>*</i>
										</label>
										{formValue.payment_type_id == 2 && (
											<p>
												Обратите внимание, что для
												заявки на проектный платеж,
												данные о проекте в коллабе,
												продукте и этапе, должны быть
												внесены менеджером проекта в его
												файле проектных данных.
											</p>
										)}
									</div>

									<div className="label-radio-btns-wrap">
										{showLabelRadioBtn(
											typePayment,
											"payment_type_id",
											setDepartments,
											`${process.env.REACT_APP_BACKEND_URL}/v1/collab/departments`,
											true
										)}
									</div>
								</div>
								<hr />

								{formValue.payment_type_id != null &&
									formValue.payment_type_id != "" && (
										<>
											<div
												className="content-form-item"
												data-required="true">
												<div className="content-form-item-desc">
													<label>
														Выберите отдел <i>*</i>
													</label>
												</div>
												{showLabelRadio(
													departments,
													"department_id",
													formValue.payment_type_id ===
														"1"
														? setTypeWorkContractor
														: "department_id",
													formValue.payment_type_id ===
														"1"
														? `${process.env.REACT_APP_BACKEND_URL}/v1/types/contractor-work`
														: `${process.env.REACT_APP_BACKEND_URL}/v1/collab/clients`,
													true
												)}
											</div>
											<hr />
										</>
									)}

								{formValue.department_id != null &&
									formValue.department_id != "" &&
									formValue.payment_type_id === "2" && (
										<>
											<div
												className="content-form-item"
												data-required="true">
												<div className="content-form-item-desc">
													<label>
														Клиент, для которого
														делались работы <i>*</i>
													</label>
												</div>

												<div className="content-select">
													<ReactSelect
														name="client_name"
														onChange={(e) =>
															handleChange(
																e,
																"",
																"client_name",
																"client_name",
																true
															)
														}
														options={clients}
														className={`${
															errorsList.invoice_scan_link
																? "error"
																: ""
														}  react-select-container`}
														classNamePrefix="react-select"
														placeholder="Введите название"
													/>
												</div>
											</div>
											<hr />
										</>
									)}

								{formValue.client_name != null &&
									formValue.client_name != "" &&
									formValue.payment_type_id === "2" && (
										<>
											<div
												className="content-form-item"
												data-required="true">
												<div className="content-form-item-desc">
													<label>
														Бюджет клиентского
														приложения <i>*</i>
													</label>
												</div>

												<div className="num">
													<input
														className={
															errorsList.client_budget_application
																? "error"
																: ""
														}
														name="client_budget_application"
														onChange={(e) =>
															handleChange(
																e,
																"",
																"client_budget_application",
																"client_budget_application",
																true
															)
														}
														value={
															valBudgetClientApp
														}
														type="text"
														placeholder="Введите значение"
													/>
												</div>
											</div>

											<hr />
										</>
									)}

								{valBudgetClientApp != null &&
									valBudgetClientApp != "" &&
									formValue.payment_type_id === "2" && (
										<>
											<div
												className="content-form-item"
												data-required="true">
												<div className="content-form-item-desc">
													<label>
														Бюджет от клиента на
														задачу <i>*</i>
													</label>
												</div>

												<div className="num">
													<input
														className={
															errorsList.client_budget_task
																? "error"
																: ""
														}
														name="client_budget_task"
														onChange={(e) =>
															handleChange(
																e,
																`${process.env.REACT_APP_BACKEND_URL}/v1/finance/contracts?filters[client]=${formValue.client_name}`,
																"client_budget_task",
																"client_budget_task",
																true
															)
														}
														type="text"
														value={
															valBudgetClientTask
														}
														placeholder="Введите значение"
													/>
												</div>
											</div>

											<hr />
										</>
									)}

								{valBudgetClientTask != null &&
									valBudgetClientTask != "" &&
									formValue.payment_type_id === "2" && (
										<>
											<div
												className={`${
													isLoading ? "_hide" : ""
												} content-form-item content-form-item--full-width content-form-item-num-contract`}
												data-required="true">
												<div className="content-form-item-desc">
													<label>
														Номер клиентского
														договора <i>*</i>
													</label>
													{/* {clientContract.length ===
													0 ? (
														<p>
															Номер клиентского
															договора по
															выбранному клиенту
															не найден, введите
															номер договора
															вручную и нажмите
															"Продолжить"
														</p>
													) : (
														<p>
															Если договор еще в
															стадии оформления,
															выберите пункт
															"Новый договор в
															процессе оформления"
														</p>
													)} */}
												</div>

												{clientContract.length === 0 ? (
													<>
														<div className="content-select">
															<input
																onChange={(e) =>
																	setNumClientContractNullResponse(
																		e
																	)
																}
																className={`${
																	errorsList.contract_number
																		? "error"
																		: ""
																}`}
																placeholder="Введите номер договора"
																defaultValue={
																	valContract
																}
																value={
																	valContract
																}
															/>
														</div>
														<div className="btns-items">
															<a
																href="#"
																onClick={(e) =>
																	setValContractFunc(
																		e
																	)
																}
																className={`btn-white btn-white-big ${
																	contractInProccess
																		? "_active"
																		: ""
																}`}>
																Новый договор в
																процессе
																оформления
															</a>

															<a
																href="#"
																onClick={(e) =>
																	setValContractFunc(
																		e
																	)
																}
																className={`btn-white btn-white-big ${
																	tender
																		? "_active"
																		: ""
																}`}>
																Тендер
															</a>
														</div>
													</>
												) : (
													<>
														<div className="content-select">
															<DatalistInput
																className={`${
																	errorsList.contract_number
																		? "error"
																		: ""
																} datalist-input datalist-input-contract`}
																items={
																	clientContractWithoutDoubles
																}
																placeholder="Введите номер договора"
																defaultValue={
																	valContract
																}
																value={
																	valContract
																}
																onSelect={(e) =>
																	setNumClientContract(
																		e
																	)
																}
															/>
														</div>
														<div className="btns-items">
															<a
																href="#"
																onClick={(e) =>
																	setValContractFunc(
																		e
																	)
																}
																className={`btn-white btn-white-big ${
																	contractInProccess
																		? "_active"
																		: ""
																}`}>
																Новый договор в
																процессе
																оформления
															</a>

															<a
																href="#"
																onClick={(e) =>
																	setValContractFunc(
																		e
																	)
																}
																className={`btn-white btn-white-big ${
																	tender
																		? "_active"
																		: ""
																}`}>
																Тендер
															</a>
														</div>
													</>
												)}
											</div>

											<hr />
										</>
									)}

								{/* {
                                    (valBudgetClientTask != null && valBudgetClientTask != '') && formValue.payment_type_id === '2' &&
                                    <>
                                        <div className="content-form-item" data-required="true">
                                            <div className="content-form-item-desc">
                                                <label>Номер клиентского договора <i>*</i></label>
                                                <p>Номер клиентского договора по выбранному клиенту не найден, введите номер договора вручную и нажмите "Продолжить"</p>
                                            </div>

                                            <div className="content-select">
                                                <input onKeyUp={(e) => setNumClientContractNullResponse(e)} className={`${errorsList.contract_number ? 'error' : ''}`} placeholder="Введите номер договора" />

                                            </div>

                                            <a href="#" onClick={(e) => setNumClientContractNullResponse(e)} className="btn-white btn-white-big">Продолжить</a>
                                        </div>

                                        <hr />
                                    </>
                                } */}

								{valContract != null &&
									valContract != "" &&
									valContract !=
										"Новый договор в процессе оформления" &&
									valContract != "Тендер" &&
									formValue.payment_type_id === "2" && (
										<>
											<div className="content-form-item">
												<div className="content-form-item-desc">
													<label>
														Номер
														клиентского приложения
													</label>
													<p>
														Номер клиентского
														приложения
													</p>
												</div>

												{clientContract.length === 0 ? (
													<div className="content-select">
														<input
															onChange={(e) =>
																setNumClientAppNullResponse(
																	e
																)
															}
															placeholder="Введите номер приложения"
															defaultValue={
																valAppendix
															}
															value={valAppendix}
														/>
													</div>
												) : (
													<div className="content-select">
														<DatalistInput
															className="datalist-input datalist-input-app"
															items={
																clientAppendixWithoutDoubles
															}
															placeholder="Введите название"
															defaultValue={
																valAppendix
															}
															value={valAppendix}
															onChange={(e) =>
																changeNumClientApp(
																	e
																)
															}
															onSelect={(e) =>
																setNumClientApp(
																	e
																)
															}
														/>
													</div>
												)}
												{/* <div className="content-select">
                                                <DatalistInput className="datalist-input datalist-input-app" items={clientAppendixWithoutDoubles} placeholder="Введите название" defaultValue={valAppendix} value={valAppendix} onChange={(e) => changeNumClientApp(e)} onSelect={(e) => setNumClientApp(e)} />
                                            </div> */}
											</div>

											<hr />
										</>
									)}

								{((valContract != null && valContract != "") ||
									valContract ==
										"Новый договор в процессе оформления" ||
									valContract == "Тендер") &&
									formValue.payment_type_id === "2" && (
										<>
											<div
												className="content-form-item"
												data-required="true">
												<div className="content-form-item-desc">
													<label>
														Внутренний проект в
														Коллабе <i>*</i>
													</label>
												</div>

												<div className="content-select">
													<ReactSelect
														name="collab_project_name"
														onChange={(e) =>
															handleChange(
																e,
																`${process.env.REACT_APP_BACKEND_URL}/v1/finance/products-and-stages?filters[projects_ids][]=${e.id}`,
																"collab_project_name",
																setProducts,
																true
															)
														}
														options={
															internalProject
														}
														defaultValue={""}
														value={
															internalProject.filter(
																function (
																	option
																) {
																	return (
																		option.value ===
																		valIntProject
																	);
																}
															) || ""
														}
														className="react-select-container"
														classNamePrefix="react-select"
														placeholder="Введите название"
													/>
												</div>
											</div>
											<hr />
										</>
									)}

								{formValue.collab_project_name != null &&
									formValue.collab_project_name != "" &&
									formValue.payment_type_id === "2" && (
										<>
											<div className="content-form-item">
												<div className="content-form-item-desc">
													<label>
														Список задач в Коллабе
													</label>
												</div>

												<div className="content-select">
													{products.map((item) => {
														Object.keys(item).map(
															(elem) => {
																let productsEl =
																	item[elem];

																productsList.push(
																	{
																		value: productsEl.name,
																		label: productsEl.name,
																	}
																);

																if (
																	!Object.keys(
																		stagesList
																	).includes(
																		productsEl.name
																	)
																) {
																	stagesList[
																		productsEl.name
																	] = [];
																}

																Object.keys(
																	productsEl.stages
																).map(
																	(item) => {
																		stagesList[
																			productsEl
																				.name
																		].push({
																			value: productsEl
																				.stages[
																				item
																			]
																				.name,
																			label: productsEl
																				.stages[
																				item
																			]
																				.name,
																		});
																	}
																);
															}
														);
													})}
													<ReactSelect
														name="collab_project_task_list"
														onChange={(e) =>
															handleChange(
																e,
																"",
																"collab_project_task_list",
																"collab_project_task_list",
																false
															)
														}
														options={taskList}
														defaultValue={""}
														value={
															taskList.filter(
																function (
																	option
																) {
																	return (
																		option.value ===
																		valTaskList
																	);
																}
															) || ""
														}
														className="react-select-container"
														classNamePrefix="react-select"
														isClearable
														placeholder="Введите название"
													/>
												</div>
											</div>
											<hr />
										</>
									)}

								{errorProdAndStage && (
									<div className="err-note">
										Невозможно продолжить заполнение формы,
										так как не заданы продукт/этап в
										проектных данных менеджера
									</div>
								)}

								{!errorProdAndStage && (
									<>
										{formValue.collab_project_name !=
											null &&
											formValue.collab_project_name !=
												"" &&
											errorProdAndStage !== true &&
											formValue.payment_type_id ===
												"2" && (
												<>
													<div
														className={`content-form-item ${
															!readyProductsAndStages
																? "content-form-item_hidden"
																: ""
														}`}
														data-required="true">
														<div className="content-form-item-desc">
															<label>
																Продукт <i>*</i>
															</label>
														</div>
														<div className="content-select">
															<ReactSelect
																onChange={(e) =>
																	handleChange(
																		e,
																		"",
																		"product",
																		"product",
																		true
																	)
																}
																options={
																	productsList
																}
																defaultValue={
																	""
																}
																value={
																	productsList.filter(
																		function (
																			option
																		) {
																			return (
																				option.value ===
																				valProduct
																			);
																		}
																	) || ""
																}
																name="product"
																className="react-select-container"
																classNamePrefix="react-select"
																placeholder="Выберите из списка"
															/>
														</div>
													</div>
													<hr />

													{valProduct != null &&
														valProduct != "" &&
														formValue.payment_type_id ===
															"2" && (
															<>
																<div
																	className="content-form-item"
																	data-required="true">
																	<div className="content-form-item-desc">
																		<label>
																			Этап{" "}
																			<i>
																				*
																			</i>
																		</label>
																	</div>

																	<div className="content-select">
																		<ReactSelect
																			onChange={(
																				e
																			) =>
																				handleChange(
																					e,
																					"",
																					"stage",
																					"stage",
																					true
																				)
																			}
																			options={
																				stagesList[
																					formValue
																						.product
																				]
																			}
																			name="stage"
																			className="react-select-container"
																			classNamePrefix="react-select"
																			placeholder="Выберите из списка"
																		/>
																	</div>
																</div>
																<hr />
															</>
														)}
												</>
											)}

										{formValue.stage != null &&
											formValue.stage != "" &&
											valProduct != null &&
											valProduct != "" &&
											formValue.payment_type_id ===
												"2" && (
												<>
													<div
														className="content-form-item"
														data-required="true">
														<div className="content-form-item-desc">
															<label>
																Дата, к которой
																относить расход
																в паспорте
																проекта <i>*</i>
															</label>
														</div>

														<Datepicker
															className={`${
																errorsList.payment_date
																	? "error"
																	: ""
															}`}
															name="payment_date"
															inputName="payment_date"
															chgDate={(e) =>
																handleChange(
																	e,
																	"",
																	"payment_date",
																	"payment_date",
																	true
																)
															}
														/>
													</div>

													<hr />
												</>
											)}

										{formValue.payment_date != null &&
											formValue.payment_date != "" &&
											formValue.payment_type_id ===
												"2" && (
												<>
													<div
														className="content-form-item"
														data-required="true">
														<div className="content-form-item-desc">
															<label>
																Дата передачи
																прав
																по этой работе
																клиенту <i>*</i>
															</label>
														</div>

														<Datepicker
															className={`${
																errorsList.assignment_date
																	? "error"
																	: ""
															}`}
															name="assignment_date"
															inputName="assignment_date"
															chgDate={(e) =>
																handleChange(
																	e,
																	`${process.env.REACT_APP_BACKEND_URL}/v1/types/contractor-work`,
																	"assignment_date",
																	setTypeWorkContractor,
																	true
																)
															}
														/>
													</div>

													<hr />
												</>
											)}

										{((formValue.assignment_date != null &&
											formValue.assignment_date != "" &&
											formValue.payment_type_id ===
												"2") ||
											(formValue.department_id != null &&
												formValue.department_id != "" &&
												formValue.payment_type_id ===
													"1")) && (
											<>
												<div
													className="content-form-item"
													data-required="true">
													<div className="content-form-item-desc">
														<label>
															Тип работы
															подрядчика <i>*</i>
														</label>
													</div>

													<div className="label-radio-btns-wrap">
														{showLabelRadioBtn(
															typeWorkContractor,
															"contractor_work_type_id",
															setTypeContractor,
															`${process.env.REACT_APP_BACKEND_URL}/v1/types/contractor`,
															true
														)}
													</div>
												</div>
												<hr />
											</>
										)}

										{formValue.contractor_work_type_id !=
											null &&
											formValue.contractor_work_type_id !=
												"" && (
												<>
													<div
														className="content-form-item"
														data-required="true">
														<div className="content-form-item-desc">
															<label>
																Тип подрядчика{" "}
																<i>*</i>
															</label>
														</div>

														<div className="label-radio-btns-wrap">
															{showLabelRadioBtn(
																typeContractor,
																"contractor_type_id",
																"contractor_type_id",
																"",
																true
															)}
														</div>
													</div>
													<hr />
												</>
											)}

										{formValue.contractor_type_id != null &&
											formValue.contractor_type_id !=
												"" && (
												<>
													<div
														className="content-form-item"
														data-required="true">
														<div className="content-form-item-desc">
															<label>
																Название
																подрядчика{" "}
																<i>*</i>
															</label>
															<p>
																Укажите название
																компании из
																Коллаба или имя
																специалиста из
																Коллаба
															</p>
														</div>

														<div className="content-select">
															<DatalistInput
																className={`${
																	errorsList.contractor_name
																		? "error"
																		: ""
																} datalist-input datalist-input-contractor`}
																items={
																	formValue.contractor_type_id ===
																	"1"
																		? companiesList
																		: specialisList
																}
																placeholder="Введите название"
																onChange={(e) =>
																	handleChange(
																		e,
																		"",
																		"contractor_name",
																		"contractor_name",
																		true
																	)
																}
																onSelect={(e) =>
																	handleChange(
																		e,
																		"",
																		"contractor_name",
																		"contractor_name",
																		true
																	)
																}
															/>
														</div>
													</div>
													<hr />
												</>
											)}

										{formValue.contractor_name != null &&
											formValue.contractor_name != "" && (
												<>
													<div
														className="content-form-item"
														data-required="true">
														<div className="content-form-item-desc">
															<label>
																Что делал
																подрядчик?{" "}
																<i>*</i>
															</label>
														</div>

														<input
															className={
																errorsList.contractor_work
																	? "error"
																	: ""
															}
															type="text"
															onChange={(e) =>
																handleChange(
																	e,
																	"",
																	"contractor_work",
																	"contractor_work",
																	true
																)
															}
															name="contractor_work"
															placeholder="Введите описание"
														/>
													</div>
													<hr />
												</>
											)}

										{formValue.contractor_work != null &&
											formValue.contractor_work != "" && (
												<>
													<div
														className={`content-form-item content-form-item-row content-form-item--full-width ${
															isProject
																? "_show"
																: ""
														}`}>
														<div className="content-form-item-desc">
															<label>
																Номер договора
																и приложения с
																подрядчиком
															</label>
															<p>
																Если на момент
																заявки вы не
																запросили
																документы у
																делопроизводителя,
																то оставьте поля
																пустыми и
																заполните эти
																данные уже в
																таблице внешних
																платежей перед
																проведением
																оплаты
															</p>
														</div>

														<div className="content-form-item-right">
															<input
																onChange={(e) =>
																	handleChange(
																		e,
																		"",
																		"contractor_contract_number",
																		"contractor_contract_number",
																		false
																	)
																}
																name="contractor_contract_number"
																defaultValue=""
																type="text"
																placeholder="Номер договора с подрядчиком"
															/>
															<div className="adds-input">
																<input
																	onChange={(
																		e
																	) =>
																		handleChange(
																			e,
																			"",
																			"contractor_contract_annex_number",
																			"contractor_contract_annex_number",
																			false
																		)
																	}
																	name="contractor_contract_annex_number"
																	defaultValue=""
																	type="text"
																	placeholder="Номер приложения с подрядчиком"
																/>
															</div>
														</div>
													</div>

													<hr />
												</>
											)}

										{formValue.contractor_work != null &&
											formValue.contractor_work != "" && (
												<>
													<div className="content-form-item-group">
														<div
															className="content-form-item"
															data-required="true">
															<div className="content-form-item-desc">
																<label>
																	Номер
																	платежа{" "}
																	<i>*</i>
																</label>
															</div>
															<input
																className={
																	errorsList.payment_number
																		? "error"
																		: ""
																}
																name="payment_number"
																onChange={(e) =>
																	handleChange(
																		e,
																		"",
																		"payment_number",
																		"payment_number",
																		true
																	)
																}
																type="number"
																placeholder="Введите номер"
															/>
														</div>

														<div
															className="content-form-item"
															data-required="true">
															<div className="content-form-item-desc">
																<label>
																	Всего
																	платежей{" "}
																	<i>*</i>
																</label>
															</div>
															<input
																className={
																	errorsList.payment_count
																		? "error"
																		: ""
																}
																name="payment_count"
																onChange={(e) =>
																	handleChange(
																		e,
																		`${process.env.REACT_APP_BACKEND_URL}/v1/types/money`,
																		"payment_count",
																		setTypeMoney,
																		true
																	)
																}
																type="number"
																placeholder="Введите количество платежей"
															/>
														</div>
													</div>

													<hr />
												</>
											)}

										{formValue.payment_count != null &&
											formValue.payment_count != "" &&
											formValue.payment_number != null &&
											formValue.payment_number != "" && (
												<>
													<div
														className="content-form-item"
														data-required="true">
														<div className="content-form-item-desc">
															<label>
																Тип денег{" "}
																<i>*</i>
															</label>
														</div>

														<div className="label-radio-btns-wrap">
															{showLabelRadioBtn(
																typeMoney,
																"money_type_id",
																"money_type_id",
																""
															)}
														</div>
													</div>

													<hr />
												</>
											)}

										{formValue.money_type_id != null &&
											formValue.money_type_id != "" && (
												<>
													<div
														className="content-form-item"
														data-required="true">
														<div className="content-form-item-desc">
															<label>
																Сумма <i>*</i>
															</label>
															<p>
																Если платеж
																проводится в
																иностранной
																валюте, то
																укажите сумму в
																рублях по курсу
																ЦБ РФ на день
																платежа. В
																комментарии ниже
																укажите сумму и
																валюту, в
																которой
																планируется
																платеж.
															</p>
														</div>

														<div className="num">
															<input
																className={
																	errorsList.amount
																		? "error"
																		: ""
																}
																name="amount"
																onChange={(e) =>
																	handleChange(
																		e,
																		"",
																		"amount",
																		"amount",
																		true
																	)
																}
																value={
																	valAmount
																}
																type="text"
																placeholder="Введите значение"
															/>
														</div>
													</div>

													<hr />
												</>
											)}

										{formValue.amount != null &&
											formValue.amount != "" && (
												<>
													<div
														className="content-form-item"
														data-required="true">
														<div className="content-form-item-desc">
															<label>
																Ссылка на задачу
																в проекте{" "}
																<a
																	target="_blank"
																	href="https://collab.cpeople.ru/projects/53">
																	"ADM —
																	Подписание и
																	обмен
																	документами"
																</a>{" "}
																с указанием
																номера
																комментария{" "}
																<i>*</i>
															</label>
															<p>
																Оплата работ
																подрядчикам
																теперь
																осуществляется
																строго при
																наличии{" "}
																<b>
																	оригиналов
																</b>{" "}
																подписанных
																документов - в
																ЭДО или в
																бумажном виде.
																Ссылки на Яндекс
																диск или на
																документ в
																коллабе не
																информативны,
																поскольку не
																дают
																представления о
																том есть ли у
																нас оригинал
																документа.
															</p>
														</div>

														<input
															className={
																errorsList.invoice_scan_link
																	? "error"
																	: ""
															}
															name="invoice_scan_link"
															onChange={(e) =>
																handleChange(
																	e,
																	"",
																	"invoice_scan_link",
																	"invoice_scan_link",
																	true
																)
															}
															type="text"
															placeholder="Вставьте ссылку"
														/>
													</div>

													<hr />
												</>
											)}

										{formValue.invoice_scan_link != null &&
											formValue.invoice_scan_link !=
												"" && (
												<>
													<div
														className="content-form-item"
														data-required="true">
														<div className="content-form-item-desc">
															<label>
																Оплатить до{" "}
																<i>*</i>
															</label>
														</div>

														<Datepicker
															autoComplete="off"
															minDate="01.01.2010"
															showMonthDropdown={
																true
															}
															showYearDropdown={
																true
															}
															dropdownMode="select"
															className={
																errorsList.pay_up_date
																	? "error"
																	: ""
															}
															name="pay_up_date"
															inputName="pay_up_date"
															chgDate={(e) =>
																handleChange(
																	e,
																	"",
																	"pay_up_date",
																	"pay_up_date",
																	true
																)
															}
														/>
													</div>

													<hr />
												</>
											)}

										{formValue.pay_up_date != null &&
											formValue.pay_up_date != "" && (
												<>
													<div className="content-form-item content-form-item--full-width">
														<div className="content-form-item-desc">
															<label>
																Комментарий
															</label>
														</div>

														<div className="textarea-block">
															<textarea
																name="comments"
																onChange={(e) =>
																	handleChange(
																		e,
																		"",
																		"comments",
																		"comments",
																		false
																	)
																}
																placeholder="Введите сообщение…"></textarea>
														</div>
													</div>

													<hr />
												</>
											)}

										{formValue.pay_up_date != null &&
											formValue.pay_up_date != "" && (
												<div className="submit-block">
													<button
														disabled={
															disabledBtn
																? true
																: false
														}
														type="submit"
														className="btn-white btn-white-big">
														Отправить
													</button>
												</div>
											)}
									</>
								)}
							</form>
						</>
					)}

					{submitForm === true && (
						<div className="ex-payment-bottom">
							<h2>
								Заявка на внешний платеж добавлена в{" "}
								<a target="_blank" href={linkTable}>
									таблицу
								</a>{" "}
								с номером {numReq}
							</h2>

							<div className="btns-bottom">
								<a href="/" className="btn-white btn-white-big">
									Вернуться на главную
								</a>
								<a
									href="#"
									onClick={repeatRequest}
									className="btn-white btn-white-big">
									Добавить еще заявку
								</a>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ExternalPayment;
