import React from 'react'
import ControlInput from '../ControlInput'

function ControlInputListExpandable (props) {
    let incrementKey = Date.now()

    const handleControlChange = function(controlEvent) {
        props.onChange({
            name: props.name,
            type: 'inputList',
            event: controlEvent.event,
            value: props.list.map(item => {
                if (item.id === controlEvent.value.id) {
                    item.value = controlEvent.value.value
                }
                return item
            })
        })
    }

    const handleRemove = function(id) {
        const value = props.list.filter(item => item.id !== id)

        props.onChange({
            name: props.name,
            type: 'inputList',
            value: value
        })
    }

    const handleAdd = function() {
        const value = props.list.concat({
            id: incrementKey++,
            value: ''
        })

        props.onChange({
            name: props.name,
            type: 'inputList',
            value: value
        })
    }

    return (
        <div className={props.className + ' clonable-inputs'}>
            {props.list.map((item) => {
                const id = item.id || incrementKey++

                return (
                    <div className="clonable-input" key={id}>
                        <ControlInput
                            className={props.className}
                            id={id}
                            name={props.name}
                            value={item.value}
                            onChange={handleControlChange}
                            onBlur={props.onBlur}
                        />
                        {props.list.length !== 1
                            ? <button type="button" className="clonable-action" onClick={() => handleRemove(id)}/>
                            : <button type="button" className="clonable-action" onClick={() => handleAdd()}/>
                        }
                    </div>
                )
            })}
            {props.list.length !== 1 &&
                <div className="clonable-footer">
                    <button type="button" className="clonable-action" onClick={() => handleAdd()}/>
                </div>
            }
        </div>
    )
}

export default ControlInputListExpandable