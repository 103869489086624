import React from 'react'

function LabelCheckboxBtn(props) {
    return (

        <label className='label-radio-btns'>
            <input type="checkbox"
                onChange={props.changeLabelCheckbox}
                name={props.name}
                value={props.value} />
            <span>{props.title}</span>
        </label>
    )
}

export default LabelCheckboxBtn