import axios from 'axios';
import { React, useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import Header from '../../components/Header/Header';
import styles from './MoveTimeProject.scss';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const MoveTimeProject = ({ changeTheme, themeBlack }) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();

  const [contractors, setContractors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          '../ajax/response-request-doc.json'
        )

        const contractorsArray = Object.entries(data["contractor-name"].options).map(([key, item]) => ({ id: key, title: item.title, label: item.title }));
        setContractors(contractorsArray);


      } catch (error) {
        console.log('error')
      }
    }

    if (token) {
      fetchData()
    } else {
      navigate('/auth');
    }

  }, [])

  return (
    <div>
      <Helmet>
        <title>Перенос времени, проставленного в проект</title>
      </Helmet>
      <div className="content content-move-time">
        <div className="container">
          <h1>Перенос времени, проставленного в проект</h1>
          <form action="">
            <div className="content-form-item">
              <div className="content-form-item-desc">
                <label htmlFor="">Проекты</label>
                <span>Укажите id проектов через запятую</span>
              </div>

              <div className="content-select">
                <ReactSelect options={contractors} className="react-select-container" classNamePrefix="react-select" placeholder="Введите название" />
              </div>

              <div className="move-time-btns">
                <a href="#" className="btn-white">Запустить</a>
                <label className='label-checkbox'>
                  <b>Добавлять телефон офиса</b>
                  <input type="checkbox" />
                  <span></span>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default MoveTimeProject