import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import styles from './ProjectDataAll.scss';

const ProjectDataAll = () => {

    const [toggleState, setToggleState] = useState(1);

    // Цветкова К.
    let tsksUrl = 'https://docs.google.com/spreadsheets/d/18lr6KiIlelOHJOG1h7zzoqMOdsirfZbFdZBo_8xCpIM/edit';
    // Фёдорова А.
    let fedaUrl = 'https://docs.google.com/spreadsheets/d/1Rv1lze5HD4s6rjfDEPxLorxYU0F6wDo-fZYe8-eZOE0/edit';
    // Брудник Д.
    let brdUrl = 'https://docs.google.com/spreadsheets/d/1HpsehwnfYt4JPvleOQyi-P3g2DN-x5kftCN4iI4Cq5c/edit';

    // Басова К.
    let baskUrl = 'https://docs.google.com/spreadsheets/d/1ggJThpFNDTL7BwePePd9eTcaDicN8bRDEGDYRLI1KSA/edit';
    // Дёмина А.
    let demaUrl = 'https://docs.google.com/spreadsheets/d/1XcAJOA7vNO8Wp3ASSpVHSo1VV2DlPqMKgvxVUMBn_t8/edit';
    // Королёв А.
    let koraUrl = 'https://docs.google.com/spreadsheets/d/1G_Ezjim1m6hnS8zuItBoPHWzuAG3s7fCg8cUmeAqG4s/edit';
    // Лагуточкина А.
    let lagaUrl = 'https://docs.google.com/spreadsheets/d/1Lf4gZbmLDsQh1lVt7JyQvQ_W5UnKTXFbqS3wueGAZrw/edit';
    // Останков Д.
    let ostdUrl = 'https://docs.google.com/spreadsheets/d/17m5oyCFO461HtuoF-EHgh6CkScXbLrzuk0xnPNfsMk8/edit';
    // Ремезов Е.
    let remeUrl = 'https://docs.google.com/spreadsheets/d/16mUEP6gHsEeHn6G4lVp7eIIIwD3DS3PfC_XgRztIKQQ/edit';
    // Фомина В.
    let fomvUrl = 'https://docs.google.com/spreadsheets/d/1JG8SeKBlME2jxG51zwSkGbMussk0Qj-hCvfzWys5ERE/edit';
    // Шик Н. 
    let shiknUrl = 'https://docs.google.com/spreadsheets/d/1wZLNtXuWD-WiVm3l4Uh8gGJc7Q5OEku1tspVmynzKXA/edit';
    // Шмелев Г.
    let shmgUrl = 'https://docs.google.com/spreadsheets/d/1IL80syGx6u25UtrtnkamrHE33ikg_TUXUr8N8BnQ0ck/edit';
    // Шувалова А.
    let shuvaUrl = 'https://docs.google.com/spreadsheets/d/1Epge2vhj8oyu7clgDMS52l5RGVdnFGG2XU7DfRFkTTs/edit';
    // Яковлев Д.
    let yakdUrl = 'https://docs.google.com/spreadsheets/d/1VokV7VLdflWH7CI7IejnMTCxtiDQx7BogKSmunWNb9M/edit';
    // Яковлева А.
    let yakaUrl = 'https://docs.google.com/spreadsheets/d/1iYAlTkhsSGU6uerKxrl68-cElcY9iy7gf-eaAX6JVu4/edit';


    const toggleTab = (e, i) => {
        e.preventDefault();
        setToggleState(i);
    }

    const getActiveClass = (i, className) =>
        toggleState === i ? className : "";

    return (
        <div>
            <Helmet>
                <title>Проектные данные менеджеров</title>
            </Helmet>

            <div className="content content-projectdata">
                <div className="container">
                    <div className="nondev-tabs">
                        <a href="#" className={`nondev-tab ${getActiveClass(1, "_active")}`} onClick={(e) => toggleTab(e, 1)}>Цветкова К.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(2, "_active")}`} onClick={(e) => toggleTab(e, 2)}>Фёдорова А.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(3, "_active")}`} onClick={(e) => toggleTab(e, 3)}>Брудник Д.</a>

                        <a href="#" className={`nondev-tab ${getActiveClass(4, "_active")}`} onClick={(e) => toggleTab(e, 4)}>Басова К.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(5, "_active")}`} onClick={(e) => toggleTab(e, 5)}>Дёмина А.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(6, "_active")}`} onClick={(e) => toggleTab(e, 6)}>Королёв А.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(7, "_active")}`} onClick={(e) => toggleTab(e, 7)}>Лагуточкина А.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(8, "_active")}`} onClick={(e) => toggleTab(e, 8)}>Останков Д.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(9, "_active")}`} onClick={(e) => toggleTab(e, 9)}>Ремезов Е.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(10, "_active")}`} onClick={(e) => toggleTab(e, 10)}>Фомина В.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(11, "_active")}`} onClick={(e) => toggleTab(e, 11)}>Шик Н.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(12, "_active")}`} onClick={(e) => toggleTab(e, 12)}>Шмелев Г.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(13, "_active")}`} onClick={(e) => toggleTab(e, 13)}>Шувалова А.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(14, "_active")}`} onClick={(e) => toggleTab(e, 14)}>Яковлев Д.</a>
                        <a href="#" className={`nondev-tab ${getActiveClass(15, "_active")}`} onClick={(e) => toggleTab(e, 15)}>Яковлева А.</a>
                    </div>
                </div>
            </div>

            <div className="frame-box">
                <div className={`frame-box__item ${getActiveClass(1, "_active")}`}>
                    <iframe src={tsksUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(2, "_active")}`}>
                    <iframe src={fedaUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(3, "_active")}`}>
                    <iframe src={brdUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(4, "_active")}`}>
                    <iframe src={baskUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(5, "_active")}`}>
                    <iframe src={demaUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(6, "_active")}`}>
                    <iframe src={koraUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(7, "_active")}`}>
                    <iframe src={lagaUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(8, "_active")}`}>
                    <iframe src={ostdUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(9, "_active")}`}>
                    <iframe src={remeUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(10, "_active")}`}>
                    <iframe src={fomvUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(11, "_active")}`}>
                    <iframe src={shiknUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(12, "_active")}`}>
                    <iframe src={shmgUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(13, "_active")}`}>
                    <iframe src={shuvaUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(14, "_active")}`}>
                    <iframe src={yakdUrl} frameBorder="0"></iframe>
                </div>

                <div className={`frame-box__item ${getActiveClass(15, "_active")}`}>
                    <iframe src={yakaUrl} frameBorder="0"></iframe>
                </div>
            </div>
        </div>
    )
}

export default ProjectDataAll