import React, { useState } from 'react'
import logo from "../../assets/img/logo.svg";
import logoBlack from "../../assets/img/logo-black.svg";
import styles from './HeaderSmall.scss';
import useStickyState from '../../assets/js/useStickyState';

const HeaderSmall = ({changeTheme, themeBlack}) => {

  return (
    <header className='header-small'>
      <div className="container">
        <div className="header-box">
          <a href="/" className="logo">
            <img src={logo} alt="" />
            <img src={logoBlack} alt="" />
          </a>

          <div className="header-info">
            <div className="label-box">
              <span>Перейти на {!themeBlack ? 'тёмную' : 'светлую'} сторону:</span>
              <label>
                <input onChange={changeTheme} type="checkbox" defaultChecked={!themeBlack} />
                <span><i></i></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderSmall