import { React, useState, useEffect } from 'react';
import useStickyState from '../../assets/js/useStickyState';
import Header from '../../components/Header/Header';
import styles from './UnloadingData.scss';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import spinner from '../../assets/img/spinner.svg';
import { useNavigate } from 'react-router-dom';

const UnloadingData = ({ changeTheme, themeBlack }) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [uploadingData, setUploadingData] = useState('');

  const stringToHTML = function (str) {
    let dom = document.querySelector('.unloading-item');
    dom.innerHTML = str;
    return dom;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_BACKEND_URL}/v1/scripts/export-collab-to-docs`,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        });

        if (data) {
          setIsLoading(false);
          console.log('Data ', data);
          setUploadingData(data);
          stringToHTML(data);
        }

      } catch (error) {
        setIsLoading(false);
        console.log('error');
      }
    }

    if (token) {
      getData()
    } else {
      navigate('/auth');
    }

  }, [])

  return (
    <div>
      <Helmet>
        <title>Выгрузка данных из коллаба</title>
      </Helmet>
      <div className="content content-unloading-data">
        {isLoading &&
          <div className="layout-fixed">
            <h2>Выгрузка выполняется...</h2>
            <img className="loading" src={spinner} alt="Spinner" />
          </div>
        }
        <div className="container">
          <h1>Выгрузка данных из коллаба</h1>

          <div className="unloading-list">
            <div className="unloading-item"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnloadingData