import React from "react";
import closeGreen from "../../assets/img/close-green.svg";

const Contacts = ({id, input, setInput, removeContacts}) => {
	return (
		<div className="contacts-item">
			{id != 1 && (
				<div
					onClick={() => removeContacts(id - 1)}
					id={id}
					className="delete-person">
					Удалить лицо{" "}
					<i>
						<img src={closeGreen} alt="" />
					</i>
				</div>
			)}
			<input
				className="contact-input"
				type="text"
				onChange={(e) =>
					setInput((prev) => ({
						...prev,
						contactPerson: e.target.value,
					}))
				}
				name={`ad_invoice-offer-client-fio-${id}`}
				placeholder="ФИО"
			/>
			<input
				className="contact-input"
				type="text"
				onChange={(e) =>
					setInput((prev) => ({
						...prev,
						contactPersonPosition: e.target.value,
					}))
				}
				name={`ad_invoice-offer-client-position-${id}`}
				placeholder="Должность"
			/>
			<input
				className="contact-input"
				type="tel"
				onChange={(e) =>
					setInput((prev) => ({
						...prev,
						contactPersonPhone: e.target.value,
					}))
				}
				name={`ad_invoice-offer-client-phone-${id}`}
				placeholder="Телефон"
			/>
			<input
				className="contact-input"
				type="text"
				onChange={(e) =>
					setInput((prev) => ({
						...prev,
						contactPersonEmail: e.target.value,
					}))
				}
				name={`ad_invoice-offer-client-email-${id}`}
				placeholder="Электронная почта"
			/>
		</div>
	);
};

export default Contacts;
