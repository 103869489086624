import React from 'react'
import styles from './ServiceItem.scss';
import arr from '../../assets/img/arr-right.svg'
import arrBlack from '../../assets/img/arr-right-black.svg'
import { Link } from 'react-router-dom';

const ServiceItem = (props) => {
  let note = props.item.textNote ? <div data-tooltip={props.item.textNote}
    className="service-item-note">? <i></i></div> : '';

  let linkBottom;
  let sizeBlock = props.item.size === '1' ? 'small-size' : 'big-size';


  let title = props.item.title.name ? <a href={props.item.title.href} className="service-item-title">{props.item.title.name}</a> : <div className="service-item-title">{props.item.title}</div>;


  if (props.item.links) {
    props.item.links.map((item, index) => (
      item.type ? linkBottom = item.href : ''
    ));
  }


  let desc = props.item.desc ? <a className="service-item-desc" target="_blank" href={linkBottom}>{props.item.desc}</a> : '';

  return (
    <div className={`service-item ${sizeBlock}`}>
      {note}


      {title}

      <div className="service-links">
        {props.item.links ? props.item.links.map((item, index) =>
          item.name === props.item.desc ? '' :
            item.href.includes('http') ?
              <a target="_blank" href={item.href} key={item.name}>{item.name}
                <i>
                  <img src={arr} alt="" />
                  <img src={arrBlack} alt="" />
                </i>
              </a> : <Link key={item.name} to={item.href}>{item.name}
                <i>
                  <img src={arr} alt="" />
                  <img src={arrBlack} alt="" />
                </i>
              </Link>
        ) : ''}
      </div>

      {desc}
    </div>
  )
}

export default ServiceItem