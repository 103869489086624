import React, { useState } from "react"
import UseCreationData from "../../api/UseCreationData"

import LabelCheckboxBtn from "../../components/LabelCheckboxBtn/LabelCheckboxBtn";
import closeGreen from "../../assets/img/close-green.svg"
import clear from "../../assets/img/clear.svg"

const Stage = ({ id, appId, remove, chgSteps }) => {
    const {creationData} = UseCreationData();
    const dataStages = creationData["action-data"].at1.apps.stages
    const dataFormat = dataStages.formats.options;

    function clearInputs(e) {
        const inputs = e.target.closest(".step").querySelectorAll("input");
        inputs.forEach((el) => el.value = '');
    }

    const [checkedOther, setCheckedOther] = useState(false);
    function onChangeCheckbox(event) {
        if (event.target.title === "Другое" || event.target.value === "f6") {
            setCheckedOther(event.target.checked);
        }
    }

    function changeNumber(e) {
        e.target.value = e.target.value.replace(/[A-Za-zА-Яа-я ]+/g, '');
        if (e.target.value !== '') {
            chgSteps(e);
        }
    }

    return (
        <div className="step" id={id}>
            {id === 1 && <div className="clear-inputs" onClick={clearInputs}>Очистить поля <i><img src={clear} alt="" /></i></div>}
            {id !== 1 && <div onClick={() => remove(id - 1)} id={id} className="delete-step">Удалить этап <i><img src={closeGreen} alt="" /></i></div>}

            <b>Этап №{id}</b>
            <div className="step-wrap">
                <div className="step-content">
                    <div className="step-content-top">
                        <div className="step-content-top-item">
                            <input type="text" onChange={chgSteps} name={`apps-${appId}_stage-${id}_name`} placeholder={dataStages.name.title} />
                        </div>
                        <div className="step-content-top-flex-wrap">
                            <div className="step-content-top-item num">
                                <input type="text" min="0" onChange={e => changeNumber(e)} name={`apps-${appId}_stage-${id}_price`} placeholder={dataStages.price.title} />
                            </div>
                            <div className="step-content-top-item">
                                <input type="text" onChange={chgSteps} name={`apps-${appId}_stage-${id}_time`} placeholder={dataStages.time.title} />
                            </div>
                        </div>
                    </div>
                    <div className="step-content-bottom">
                        <b>{dataStages.formats.title}</b>
                        <span>Выберите один или несколько вариантов</span>
                        <div className="label-radio-btns-wrap">
                            {
                                Object.keys(dataFormat).map(item => {
                                    return <LabelCheckboxBtn changeLabelCheckbox={e => onChangeCheckbox(e)}
                                        key={dataFormat[item].id} name={`apps-${appId}_stage-${id}_formats[]`} value={dataFormat[item].id} title={dataFormat[item].title} />
                                })
                            }
                        </div>
                    </div>

                    <div className={`adds-input${!checkedOther ? ' _hidden' : ''}`}>
                        <input type="text" name={`apps-${appId}_stage-${id}_other-format-text`} placeholder="Введите форматы через запятую" />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Stage